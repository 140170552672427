import { ethers } from 'ethers';
import { SWAP, TOKEN_ABI } from './abi/ROUTER_ABI';
import { getContract } from './ContractService';
import detectEthereumProvider from '@metamask/detect-provider';
import { Alert } from '../helpers/AlertHelpers';
export const checkMetaMask = async (): Promise<any> => {
  const provider = await detectEthereumProvider();
  const installedMetaMask = window?.ethereum && window?.ethereum?.isMetaMask;

  if (!provider || installedMetaMask === undefined) {
    Alert.error1(
      `<div className="text-muted">
         Vui lòng cài đặt tiện ích mở rộng trình duyệt Metamask để kết nối ví Metamask.
       </div>
       <br />
      <a class='btn btn-primary text-white' href='https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-started-with-MetaMask' target="_blank">Cài đặt</a>
     `,
      true,
      false
    );
    return false;
  }

  if (provider !== window.ethereum || window.ethereum.isCoin98) {
    Alert.error('Nếu bạn đã cài đặt nhiều ví, hãy tắt các ví khác. Sau đó thử lại.', true);
    return false;
  }
  return provider;
};

export async function connectWeb3() {
  const ethereum = await checkMetaMask();

  let signer;
  let provider;
  if (!ethereum) {
    return { signer, provider };
  } else {
    await ethereum.enable();
    console.log('connectWeb3==>', ethereum);
    const provider = new ethers.providers.Web3Provider(ethereum, 'any');

    signer = provider.getSigner();

    return { signer, provider };
  }
}
