import axios from 'axios';
import queryString from 'query-string';
import qs from 'qs';

import appConfig from '../appConfig';
import { Alert } from '../helpers/AlertHelpers';

const pageZero = false;
const AxiosInstance = axios.create({
  baseURL: appConfig.api.baseUrl,
});

AxiosInstance.interceptors.request.use((config) => {
  if (!config.url) {
    return config;
  }
  if (pageZero) {
    const _params = config.params ? modifyPaging(config.params) : '';
    return {
      ...config,
      url: modifyPaging(config.url),
      params: _params,
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { indices: false });
      },
    };
  } else {
    return {
      ...config,
      params: config.params,
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { indices: false });
      },
    };
  }
  return config;
});

AxiosInstance.interceptors.response.use(
  (res) => {
    if (pageZero) {
      const { config, data } = res;
      // if (config.url && config.url.indexOf('user-requests') > -1) {
      //   console.log('axios hooook', config.params, data);
      // }
      let myData = data;
      if (data.page !== undefined) {
        myData = { ...data, page: data.page + 1 };
      }
      return {
        ...res,
        config: {
          ...config,
          url: modifyPaging(config.url as string),
          params: config.params ? modifyPaging(config.params) : '',
        },
        data: myData,
      };
    }
    return res;
  },
  async (err) => {
    console.log('Axios error', err);
    if (!err.response) {
      Alert.error('Network error');
      return Promise.reject(err);
    }
    // if (err.response.status === 422) {
    //   Alert.error('Bad request');
    // }
    if (err.response.status === 401) {
      Alert.error('Unauthorized');
      logout();
    }
    if (err.response.status === 403) {
      Alert.error('Unauthorized');
      logout();
    }
    if (err.response.status === 400) {
      Alert.error('Bad request');
    }

    if (err.response.status >= 500) {
      Alert.error('Oops! Server error');
    }

    return Promise.reject(err);
  }
);

const logout = () => {
  localStorage.removeItem('cxp-auth');
  localStorage.removeItem('user');
  localStorage.removeItem('address');
  localStorage.removeItem('persist:cxp-auth');
  document.location.assign('/auth');
};

const modifyPaging = (url: string | any): string => {
  if (typeof url === 'object') {
    if (url.page) {
      return { ...url, page: url.page - 1 };
    } else {
      return url;
    }
  }
  const myUrl = urlStringParser(url);
  const queryParams = myUrl.query;
  if (queryParams && queryParams.page) {
    let newPage = parseInt(queryParams.page as string) - 1;
    return urlStringBuilder({
      ...myUrl,
      query: { ...queryParams, page: newPage },
    });
  } else {
    return url;
  }
};

export const urlStringParser = (url: string) => {
  return queryString.parseUrl(url);
};

export const urlStringBuilder = (params: any) => {
  return queryString.stringifyUrl(params);
};
export const queryStringBuilder = (params: any) => {
  let str = '';

  if (!params) {
    return str;
  }
  str = queryString.stringify(params);
  return str;
};

export default AxiosInstance;
