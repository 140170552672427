/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';
// import { register } from '../../store/auth/AuthCRUD';
import { Link } from 'react-router-dom';
import { PasswordMeterComponent } from '../../assets/ts/components';
import { YupI18n } from '../../helpers/yup';
import AuthService from '../../services/AuthService';
import * as auth from '../../store/auth';

const initialValues = {
  name: '',
  email: '',
  password: '',
  acceptTerms: false,
};

export function Registration() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const intl = useIntl();
  YupI18n();

  const registrationSchema = Yup.object().shape({
    name: Yup.string().min(3).max(50).required(),
    email: Yup.string().email().min(3).max(50).required(),
    password: Yup.string().min(3).max(50).required(),

    acceptTerms: Yup.bool().required(intl.formatMessage({ id: 'AUTH.VALIDATION.AGREEMENT_REQUIRED' })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        AuthService.register(values.email, values.password, values.name)
          .then((data) => {
            setLoading(false);
            setSubmitting(false);

            if (!data.status) {
              setError(data.msg);
            } else {
              dispatch(auth.actions.register(data.data));
              localStorage.setItem('email', values.email);
              console.log(data);
            }
          })
          .catch(() => {
            setLoading(false);
            setSubmitting(false);
            setStatus(intl.formatMessage({ id: 'AUTH.REGISTER.FAIL' }));
          });
      }, 1000);
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className="mb-10 text-center">
        {/* begin::Title */}
        <h1 className="text-dark mb-3">{intl.formatMessage({ id: 'AUTH.CreateAnAccount' })}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        {/* <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Forgot Password ?
          </Link>
        </div> */}
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Sign in with Google
      </button> */}
      {/* end::Action */}

      {/* <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div> */}
      {error && (
        <div className="fv-plugins-message-container invalid-feedback_error">
          <div className="fv-help-block text-danger"> {error}.</div>
        </div>
      )}
      {/* {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )} */}

      {/* begin::Form group Firstname */}
      <div className="fv-row mb-7">
        {/* <div className='col-xl-6'> */}
        <label className='class="form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: 'FORM.name' })}</label>
        <input
          placeholder={intl.formatMessage({ id: 'FORM.name' })}
          type="text"
          autoComplete="off"
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.name && formik.errors.name,
            },
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
        />
        {formik.touched.name && formik.errors.name && (
          <div className="fv-plugins-message-container invalid-feedback_error">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.name}</span>
            </div>
          </div>
        )}
        {/* </div> */}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">{intl.formatMessage({ id: 'FORM.email' })}</label>
        <input
          placeholder={intl.formatMessage({ id: 'FORM.email' })}
          type="email"
          autoComplete="off"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container invalid-feedback_error">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className="mb-10 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">{intl.formatMessage({ id: 'FORM.password' })}</label>
          <div className="position-relative mb-3">
            <input
              type="password"
              placeholder={intl.formatMessage({ id: 'FORM.password' })}
              autoComplete="new-password"
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container invalid-feedback_error">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">{intl.formatMessage({ id: 'AUTH.REGISTER.PasswordHint' })}</div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      {/* <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div> */}
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-5">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input"
            type="checkbox"
            id="kt_login_toc_agree"
            {...formik.getFieldProps('acceptTerms')}
          />
          <label className="form-check-label fw-bold text-gray-700 fs-6" htmlFor="kt_login_toc_agree">
            <FormattedMessage
              id="AUTH.AcceptTermAndConditions"
              values={{
                link: (
                  <Link to="/auth/terms" className="ms-1 link-primary">
                    {intl.formatMessage({ id: 'AUTH.TermAndConditions' })}
                  </Link>
                ),
              }}
            />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className="fv-plugins-message-container invalid-feedback_error">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && (
            <span className="indicator-label">{intl.formatMessage({ id: 'AUTH.GENERAL.SIGNUP_BUTTON' })}</span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'PleaseWait' })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
          >
            {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}
