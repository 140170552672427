import moment, { Moment } from 'moment';
import { ApiListModel } from '../types/Api';
import { DateRangeTypes } from '../types/DateRangeTypes';
import { Chains } from '../types/Chain';

export function wrapPromise(promise: Promise<ApiListModel | any>) {
  let status = 'pending';
  let response: ApiListModel;

  const suspender = promise.then(
    (res) => {
      status = 'success';
      response = res;
    },
    (err) => {
      status = 'error';
      response = err;
    }
  );
  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        // throw response;
        return false;
      } else if (status === 'success') {
        return response;
      }
    },
  };
}

export const getDateRangeByRageType = (rageType: DateRangeTypes): { startDate: string; endDate: string } => {
  let startDate: Moment = moment();
  let endDate: Moment = moment();
  switch (rageType) {
    case 'day':
      startDate.subtract(1, 'days');
      break;
    case 'week':
      startDate.subtract(1, 'weeks');
      break;
    case 'month':
      startDate.subtract(1, 'months');
      break;
    case 'year':
      startDate.subtract(1, 'years');
      break;
    default:
      break;
  }

  return {
    startDate: startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
    endDate: endDate.format('YYYY-MM-DDTHH:mm:ssZ'),
  };
};

export const getChainColor = (chainName: string): string => {
  let res: string = 'info';
  switch (chainName) {
    case 'VCHAIN':
      res = 'primary';
      break;
    case 'BINANCE':
      res = 'warning';
      break;
    case Chains.POLYGON:
      res = 'success';
      break;
    default:
      break;
  }
  return res;
};

const filterByKey = (items: any[], key: string, value?: string | number) => {
  if (!items) {
    return [];
  }
  return items.filter((item) => item[key] === value);
};
const colorRenderChart = (chainName: string) => {
  switch (chainName) {
    case 'BINANCE':
      return '#FAC435';
    case Chains.POLYGON:
      return '#627EEA';
    case 'VCHAIN':
      return '#4153AF';
  }
};

const checkPoolBalance = (fromToken: any, toToken: any): string => {
  let error = '';
  if (
    fromToken.balance.total !== 0 &&
    toToken.balance.total !== 0 &&
    (fromToken?.inputAmount || toToken?.inputAmount)
  ) {
    let fromAmount = fromToken.balance.total + (fromToken?.inputAmount || 0);
    let toAmount = toToken.balance.total + (toToken?.inputAmount || 0);
    if (fromAmount < 0 && toAmount < 0) {
      error = `Không đủ ${fromToken.symbol} và ${toToken.symbol}`;
    }
    if (fromAmount < 0 && toAmount > 0) {
      error = `Không đủ ${fromToken.symbol}`;
    }
    if (fromAmount > 0 && toAmount < 0) {
      error = `Không đủ - ${toToken.symbol}`;
    }
  }

  return error;
};
const UtilHelpers = {
  getChainColor,
  getDateRangeByRageType,
  filterByKey,
  colorRenderChart,
  checkPoolBalance,
};

export default UtilHelpers;

export const buildQueryString = (args: any) => {
  const params = new URLSearchParams({});
  for (let key in args) {
    if (args.hasOwnProperty(key)) {
      if (args[key]) {
        if (Array.isArray(args[key])) {
          for (const value of args[key]) {
            params.append(key, value.toString());
          }
        } else {
          params.append(key, args[key].toString());
        }
      }
    }
  }
  return params.toString();
};

export function uuid() {
  function getRandomSymbol(symbol: string) {
    var array;

    if (symbol === 'y') {
      array = ['8', '9', 'a', 'b'];
      return array[Math.floor(Math.random() * array.length)];
    }

    array = new Uint8Array(1);
    window.crypto.getRandomValues(array);
    return (array[0] % 16).toString(16);
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, getRandomSymbol);
}

export function sliceSignature(signature: any) {
  let r = signature.slice(0, 66);
  let s = '0x' + signature.slice(66, 130);
  let v1 = '0x' + signature.slice(130, 132);
  let v = Number(v1);

  if (v < 27) {
    v = v + 27;
  }

  return { r, s, v };
}
