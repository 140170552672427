export enum Chains {
  BINANCE = 'BINANCE',
  POLYGON = 'POLYGON',
}

export enum Contracts {
  PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
  VIC = 'VIC',
}

export interface INetworks {
  BINANCE: INetwork;
  POLYGON: INetwork;
}

export interface INetwork {
  active: boolean;
  chainId: number;
  provider: Provider;
  contracts: any;
  tokens: any;
  gasPrice: number;
}

interface Provider {
  endpoint: string;
  eip1559Supported: boolean;
  explorer: string;
  apiEndpoint: string;
  coinSymbol: string;
  coinDecimals: number;
}

export interface IChain {
  id: number;
  name: string;
  explorer: string;
  logoUrl: string;
  gasPrice: number;
  nativeCurrency: {
    symbol: string;
    name: string;
    decimals: number;
  };
  contracts: {
    PAYMENT_GATEWAY: {
      address: string;
    };
    USDT: {
      address: string;
    };
    VIC: {
      address: string;
    };
    VNFT: {
      address: string;
    };
  };
  tokens: {
    USDT: {
      address: string;
      decimals: number;
      isOwner: boolean;
      name: string;
      symbol: string;
      type: string;
    };
    VIC: {
      address: string;
      decimals: number;
      isOwner: boolean;
      name: string;
      symbol: string;
      type: string;
    };
    VNFT: {
      address: string;
      decimals: number;
      isOwner: boolean;
      name: string;
      symbol: string;
      type: string;
    };
  };
}

export interface INftChain {
  code: string;
  name: string;
  id: number;
  coinName: string;
  endpoint: string;
  explorer: string;
  coinSymbol: string;
  apiEndpoint: string;
  coinDecimals: number;
  eip1559Supported: boolean;
  createdAt: string;
  updatedAt: string;
}
