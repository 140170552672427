import { createRoot } from 'react-dom/client';
// Redux
// https://github.com/rt2zz/redux-persist
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import * as _redux from './setup';
import store, { persistor } from './store/Store';
import { Chart, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Apps
import { MetronicI18nProvider } from './i18n/Metronici18n';
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './assets/sass/style.scss';
import './assets/sass/style.react.scss';
import './index.scss';

import { AppRoutes } from './routing/AppRoutes';
import AxiosInstance from './services/axios';
import { AuthProvider } from './store/auth';
import WalletProvider from './providers/WalletProvider';
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(AxiosInstance, store);

Chart.register(...registerables);

const queryClient = new QueryClient();
const container = document.getElementById('root');

if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <Provider store={store}>
          {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
          <PersistGate persistor={persistor} loading={<div>Loading...</div>} />
          <AuthProvider>
            <WalletProvider>
              <AppRoutes />
            </WalletProvider>
          </AuthProvider>
          <ToastContainer />
        </Provider>
      </MetronicI18nProvider>
    </QueryClientProvider>
  );
}
