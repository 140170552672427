import { call, put, takeEvery } from 'redux-saga/effects';
import CurrencyService from '../../services/CurrencyService';
import { fetchCurrencyFailure, fetchCurrencySuccess } from './actions';
import { GET_CURRENCIES_REQUEST } from './actionTypes';
import { Currency } from '../../types/Currency';

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* fetchCurrencySaga() {
  try {
    const tokenData: { page: {}; items: Currency[] } = yield call(CurrencyService.getCurrencyList);
    yield put(fetchCurrencySuccess({ currencies: tokenData.items })); // why
  } catch (e: any) {
    console.log('GET DATA ERROR', e);
    yield put(fetchCurrencyFailure({ error: e.message }));
  }
}

// Starts fetchUser on each dispatched USER_FETCH_REQUESTED action
// Allows concurrent fetches of user
function* tokenSaga() {
  yield takeEvery(GET_CURRENCIES_REQUEST, fetchCurrencySaga);
}

export default tokenSaga;
