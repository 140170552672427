import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { Alert, KTSVG, formatCurrency } from '../../helpers';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import NFTDappService from '../../services/NFTDappService';
import FormikNftSelect from './FormikNftSelect';
import { IDenominations, INFT } from '../../types/MultiTransfer';
import { Chains, IChain } from '../../types/Chain';
import { CurrencySymbols } from '../../types/CurrencyBalance';

import { useSelector } from 'react-redux';
import { getChainsSelector } from '../../store/chain/selectors';
import { noncesOf, signNFTData, switchChain } from '../../services/ContractService';
import moment from 'moment';
import { connectWeb3 } from '../../services/SwapServices';
import { sliceSignature } from '../../helpers/UtilsHelpers';
import OverviewOwnedNftsModal from './OverviewOwnedNftsModal';
import { TListModel } from '../../types/Api';
import { useWallets } from '../../providers/WalletProvider';
import useChain from '../../hooks/useChain';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Web3 from 'web3';



const PageSendNFT = () => {
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalNFT, setTotalNFT] = useState<number>(0)
  // const [fee, setFee] = useState<any>();
  const [selectedNFTs, selectNFTs] = useState<INFT[]>([]);
  const [showNFTAddNewModal, setShowNFTAddNewModal] = useState(false);
  const [showOverviewOwnedNftsModal, setOverviewOwnedNftsModal] = useState(false);
  const [data, setData] = useState<TListModel<INFT>>();
  const [page, setPage] = useState(1);
  const chains = useSelector(getChainsSelector);
  const chain: any = chains.find(chain => chain.name === Chains.POLYGON);
  const [chainActive, setChainActive] = useState<IChain>(chain)
  const [permits, setPermits] = useState<any[]>([]);
  const [permitNFTs, setPermitNFTs] = useState<INFT[]>([]);
  const [summary, setSummary] = useState<IDenominations>();
  const [transferredNfts, setTransferredNfts] = useState<any>([])
  const { values, } = useWallets()
  const { getChainsList, } = useChain();
  const intl = useIntl();
  const totalPermitNFTsValue =
    selectedNFTs.reduce((total: number, nft) => {
      const value = nft.description.replaceAll(",", "").replaceAll(".", "");
      return total + parseInt(value);
    }, 0)

  useEffect(() => {

    loadData();
    loadSummary();

  }, [selectedNFTs])

  const chainData = async () => {
    const chain = getChainsList(chainActive.id ?? Number(localStorage.getItem('fromChainId')))

    setChainActive(chain[0])
  }
  useEffect(() => {

    chainData()
    console.log(localStorage.getItem("fromChainId"))
    //console.log("chainActive", chainActive)
  }, [values.chainId, chainActive])
  const loadData = async () => {
    setLoading(true);
    const chainCode = chainActive?.name ?? Chains.POLYGON;
    const data = await NFTDappService.getMyNFTs({
      chainCode,
      page,
      size: 20
    });
    setData(data);
    setLoading(false);
  }

  const handleChange = (page: number) => {
    setPage(page)
  };

  const loadSummary = async () => {
    setDataLoading(true);
    const _chain = getChainsList(Number(localStorage.getItem('chainId')) ?? Number(localStorage.getItem('fromChainId')))
    const chainCode = _chain[0].name ?? Chains.POLYGON;

    const data = await NFTDappService.summary({});
    setSummary(data);
    let totalQuantity = 0;
    if (data) {
      data?.denominations.forEach((item: any) => {
        totalQuantity += item.quantity;
      });
    }
    setTotalNFT(totalQuantity)
    setDataLoading(false);
  }
  // console.log(summary)
  const initialValues = {
    recipient: "",
    // nftIds: "1",
  };
  const schema = Yup.object().shape({
    recipient: Yup.string().required('Bạn chưa nhập người nhận')
      .test('is-address', 'InvalidAddress', function (value) {
        const { path, createError } = this;
        return (
          Web3.utils.isAddress(value ?? '') ||
          createError({
            path,
            message: intl.formatMessage({
              id: 'Form.validation.invalidAddress',
              defaultMessage: 'Địa chỉ ví không đúng định dạng',
            }),
          })
        );
      }),
  });

  const onSubmit = async ({
    recipient
  }: {
    recipient: string
  }, { setStatus, setSubmitting }: any) => {
    setLoading(true);
    if (selectedNFTs.length === 0) {
      return;
    }
    const chainId = localStorage.getItem("chainId") ?? chain.id
    const resData: any = await signNFT(selectedNFTs, chainId)
    const _permits: any = resData._permits

    if (resData?._permitsNts.length > 0) {
      const _chain = getChainsList(Number(localStorage.getItem('chainId')) ?? Number(localStorage.getItem('fromChainId')))
      setChainActive(_chain[0])
      const chainCode = _chain[0]?.name ?? Chains.POLYGON;
      const _nftAddresses = resData?._permitsNts.map((nft: any) => nft.nftAddress)
      console.log("recipients:", Array(resData?._permitsNts.length).fill(formik.values.recipient),)


      const blockchainData: {
        chainCode: any;
        permit: {
          // tokenId: string,
          deadline: number;
          v: number;
          r: string;
          s: string;
        }; //[];
        recipients: string[];
        nftAddress: string;
        permitBatch: {
          details: string[],
          spender: string;
          sigDeadline: number
        }

        // tokenIds: number[];
      } = {
        chainCode,
        permit: _permits,
        recipients: Array(resData?._permitsNts.length).fill(formik.values.recipient),
        nftAddress: _nftAddresses[0],
        // tokenIds: resData?._permitsNts.map((nft: any) => nft.tokenId),
        permitBatch: {
          details: resData._details,
          spender: _chain[0]?.contracts.PAYMENT_GATEWAY.address,
          sigDeadline: _permits.deadline
        },

      }
      try {
        const data = await NFTDappService.transferNFTs(blockchainData);

        if (data && data?.txHash) {

          loadData();
          selectNFTs(
            selectedNFTs.filter(nft => {
              const resolved = resData?._permitsNts.find((pn: any) => pn.tokenId === nft.tokenId);
              return !resolved;
            })
          );
          setTransferredNfts(data.transferredNfts)
          console.log(data?.transferredNfts)
          setPermits([]);
          setLoading(false);
          setPermitNFTs([]);
          loadSummary();
          Alert.success('Gửi NFT thành công.', true);
        }
      } catch (e) {
        setPermits([]);

        setPermitNFTs([]);

        Alert.error('Gửi NFT không thành công', true);
        setLoading(false);
        setSubmitting(false);
      }
      setLoading(false);
      setSubmitting(false);
    }

  };
  const signNFT = async (nft: INFT[], chainId1: string) => {
    if (!chainActive) {
      return;
    }
    // // try {
    const { signer } = await connectWeb3();

    let _permitsNts: any = []
    let _permits: any = []

    if (chainId1) {
      try {
        await switchChain(Number(chainId1));
      } catch (e) {
        Alert.error('Thao tác bị từ chối trên Ví của bạn. Hãy kiểm tra lại!');
        setLoading(false);
        return false;

      }
    }

    const chain: any = getChainsList(Number(localStorage.getItem('chainId')) ?? Number(localStorage.getItem('fromChainId')))
    const chainId: any = chain[0].id
    const now = moment();
    const nowWith10min = now.add(1, 'days')
    const details: any = []
    // nft.forEach()
    const tokenIds: any = []

    nft.forEach((item: any, key) => {
      // console.log("sign data", item.tokenId)
      tokenIds.push(...[item?.tokenId])
      _permitsNts.push(...[item])

    })
    //
    const nonce: any = await noncesOf({ contractAddress: nft[0].nftAddress, tokenId: tokenIds });
    console.log("nonce", nonce, _permitsNts)
    const deadline = nowWith10min.unix();
    nft.forEach(async (item: any, key) => {
      details.push(...[{ tokenId: item.tokenId, nonce: nonce[key] ? Number(nonce[key]) + 1 : 1 }])

    })

    const signedData = await signNFTData({
      chainId,
      nftContract: chain[0]?.contracts.VNFT.address,
      paymentContract: chain[0]?.contracts.PAYMENT_GATEWAY.address,
      signer,
      nonce: nonce ? nonce + 1 : 1,
      tokenId: nft[0].tokenId,
      details,
      deadline
    }).catch((e: any) => {
      Alert.error('Không thể yêu cầu ký duyệt', true);
      setLoading(false);
      return false;
    });
    console.log("signedData", signedData)
    const { r, s, v } = sliceSignature(signedData)

    _permits = {
      deadline,
      v,
      r,
      s,
    }


    setPermitNFTs([...permitNFTs, nft[0]])
    return { _permitsNts: _permitsNts, _permits: _permits, _details: details }

  }
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });
  const copy = async (val: string | undefined) => {
    const text = await navigator.clipboard.readText()
    formik.setFieldValue('recipient', text)
  };
  return (
    <section className='w-lg-500px bg-white rounded shadow-sm mx-auto'>
      <div className="card">
        <div className="card-body p-0">
          <div className='row px-10 pt-10'>
            <h3 className="card-label">
              Gửi NFT
            </h3>
          </div>
          <div className='border-1 border-bottom p-4 px-10'>
            <div className='row mb-2'>
              <div className='col fw-bold '>Bạn đang có:</div>
              <div className='col text-end'>
                <a onClick={() => setOverviewOwnedNftsModal(true)} className='text-decoration-underline '>{totalNFT} NFTs</a>
              </div>
            </div>
            <div className='row'>
              <div className='col fw-bold '>Tổng giá trị NFT:</div>
              <div className='col text-end'>{formatCurrency(summary?.totalValue ?? 0, CurrencySymbols.VIC, false)}</div>
            </div>
          </div>
          <div className='border-1 border-bottom p-10'>
            <form onSubmit={formik.handleSubmit} noValidate >
              <div className="mb-6">
                <label className="form-label required">
                  Địa chỉ ví nhận
                </label>
                <div className="d-flex">
                  <input
                    className="form-control me-4"
                    required
                    {...formik.getFieldProps('recipient')}
                    autoComplete="off"
                  />

                  <button
                    className="btn btn-icon btn-primary px-3"
                    onClick={() => {

                      copy(formik.values.recipient);
                    }}
                    type="button"
                  >
                    <img src="/img/svg/paste.svg" width="15px" />
                  </button>
                </div>
                {formik.touched.recipient && formik.errors.recipient && (
                  <div className="fv-plugins-message-container invalid-feedback_error">
                    <div className="fv-help-block">{formik.errors.recipient}</div>
                  </div>
                )}
              </div>
              <div className='round round_Nft border px-6' >
                <FormikNftSelect {...{
                  formik,
                  selectedNFTs,
                  selectNFTs,
                  data,
                  showNFTAddNewModal,
                  setShowNFTAddNewModal,
                  signNFT,
                  setPermitNFTs,
                  permitNFTs,
                  permits,
                  setPermits,
                  handleChange,
                  loading
                }} />
                <div className='row mb-2'>
                  <div className='col'>Tổng giá trị:</div>
                  <div className='col text-end text-primary fw-bold'>{totalPermitNFTsValue ? formatCurrency(totalPermitNFTsValue || 0, CurrencySymbols.VIC, true, 4) : "0"}  {' '} <img src='../img/vchain1.png' width={20} height={20} /></div>
                </div>
              </div>
              <div className='mb-6'>
                <div className='round round_Nft border p-2'>
                  {/* <div className='col '> */}
                  <div className="d-flex justify-content-between">
                    <div className='d-flex'>
                      <OverlayTrigger
                        placement="top"

                        overlay={
                          <Tooltip id="tooltip-copy-to-clipboard">
                            <div>
                              <span>
                                Hệ thống sẽ trả phí gas cho bạn! Giúp bạn tiết kiệm và dễ dàng thực hiện giao dịch hơn mà không cần phải có chain coin trong ví
                              </span>
                            </div>
                          </Tooltip>
                        }
                      >
                        <div>
                          <KTSVG path="" className={`las la-info-circle text-primary me-2`} />
                        </div>
                      </OverlayTrigger>
                      Phí gas
                    </div>
                    <div className=' d-flex align-content-end'>
                      Miễn phí
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button type='submit' className="btn btn-primary btn-lg w-100 rounded-pill " disabled={formik.isSubmitting || !formik.isValid || selectedNFTs.length === 0}>
                  {!loading && <>Xác nhận</>}
                  {loading && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                      <FormattedMessage id="PleaseWait" defaultMessage="Đang tạo..." />{' '}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div >
      {
        showOverviewOwnedNftsModal && <OverviewOwnedNftsModal show={showOverviewOwnedNftsModal} onHide={() => {
          setOverviewOwnedNftsModal(!showOverviewOwnedNftsModal)
        }} />
      }

    </section >
  );
};
export default PageSendNFT;