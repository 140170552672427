import { title } from 'process';
import Swal from 'sweetalert2';

const success = (msg: string, isHtml?: boolean, showConfirmButton = true) =>
  Swal.fire({
    text: msg,
    html: isHtml ? msg : '',
    icon: 'success',
    showConfirmButton,
  });

const error1 = (msg: string, isHtml?: boolean, showConfirmButton = true) =>
  Swal.fire({
    text: msg,
    html: isHtml ? msg : '',
    icon: 'error',
    showConfirmButton,
  });
const error = (msg: string, isHtml?: boolean, showConfirmButton = true, titleText?: string) =>
  Swal.fire({
    title: titleText,
    text: msg,
    html: isHtml ? msg : '',
    icon: 'error',
    showConfirmButton,
    showCancelButton: !showConfirmButton ?? true,
    cancelButtonText: `Đóng`,
    // cancelButtonColor: '#DEEBFF',
  });

const warning = (msg: string, isHtml?: boolean, showConfirmButton = true) =>
  Swal.fire({
    text: msg,
    html: isHtml ? msg : '',
    icon: 'warning',
    showConfirmButton,
  });

const info = (msg: string, isHtml?: boolean, showConfirmButton = true) =>
  Swal.fire({
    text: msg,
    html: isHtml ? msg : '',
    icon: 'info',
    showConfirmButton,
  });
const confirm = (msg: string, isHtml?: boolean) =>
  Swal.fire({
    text: msg,
    html: isHtml ? msg : '',
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: 'Đồng ý',
    cancelButtonText: `Quay lại`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      return true;
    } else {
      return false;
    }
  });
const Alert = {
  success,
  error,
  error1,
  warning,
  info,
  confirm,
};
export { Alert };
