import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getPendingSelector, getTransactionSelector } from '../../store/transaction/selectors';
import { IFilterTransaction, TransactionItem } from '../../store/transaction/types';
import DateTime from '../../components/common/DateTime';
import { TRANSACTION } from '../../store/transaction/actions';
import { Table } from '../../components/table';
import { formatCurrency, getExplorerLink } from '../../helpers';
import { CurrencySymbols } from '../../types/CurrencyBalance';
import { Address } from '../../components/token/Address';
import { getChainsSelector } from '../../store/chain/selectors';
import LabelStatus from "../../components/common/LabelStatus"
import { TxStatusClassNames, TxTypeClassNames, TxTypes } from '../../types/Transaction';
import Pagination from '../../components/common/pagination/Pagination';

export const Transactions = ({ title, filter, children, handleChange }: {
  title: string;
  className?: string;
  filter: IFilterTransaction;
  handleChange: (params: IFilterTransaction) => void;
  children: React.ReactChild | React.ReactNode;
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const transaction = useSelector(getTransactionSelector);
  const chains = useSelector(getChainsSelector);
  const loading = useSelector(getPendingSelector);
  const getChainId = (chain: any) => {
    // console.log(chains, chain.chainCode)
    return chains.find((item) => item.name === chain.chainCode)
  }
  const columns = [
    {
      title: formatMessage({ id: 'TransactionDate' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => <DateTime value={date} />,
    },
    {
      title: formatMessage({ id: 'TRANSACTION.TransactionType' }),
      dataIndex: 'txType',
      key: 'txType',
      render: (type: string) => <LabelStatus statusClasses={TxTypeClassNames} status={type} />
    },
    {
      title: `Mã thanh toán`,
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <div className='d-flex align-items-center gap-2'>
        <Address
          address={id}
        />
      </div>

    },
    {
      title: "Địa chỉ gửi",
      dataIndex: 'senderAddress',
      key: 'senderAddress',
      render: (senderAddress: string, chainCode: string) => <div className='d-flex align-items-center gap-2'>
        <Address
          address={senderAddress}
          link={getExplorerLink(getChainId(chainCode)?.id, senderAddress, 'address')}
        />
      </div>
    },
    {
      title: `Địa chỉ nhận`,
      dataIndex: 'recipientAddress',
      key: 'recipientAddress',
      // className: "text-end",
      render: (recipientAddress: string, chainCode: string) =>
        <div className='d-flex align-items-center gap-2'>
          <Address
            address={recipientAddress}
            link={getExplorerLink(getChainId(chainCode)?.id, recipientAddress, 'address')}
          />
        </div>
      //  <div className="fw-semibold text-end">{recipientAddress}</div>,
    },
    {
      title: `Số lượng NFT`,
      dataIndex: 'quantity',
      key: 'quantity',
      className: "text-end",
      render: (quantity: number) => <div className="fw-semibold text-end">{quantity}</div>,
    },
    {
      title: "Tổng giá trị",
      dataIndex: 'amount',
      key: 'amount',
      className: "text-end",
      render: (amount: number) => <div className="fw-semibold text-end">{formatCurrency(amount, CurrencySymbols.VIC, true)} VIC </div>,
    },
    {
      title: formatMessage({ id: 'ACCOUNT.SyncStatus' }),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {

        return (
          <LabelStatus status={status} statusClasses={TxStatusClassNames} />
        );
      },
    },
    // {
    //   title: "Mạng giao dịch trên chain",
    //   dataIndex: 'chainName',
    //   key: 'chainName',
    //   render: (chainName: string) => <div className='d-flex align-items-center gap-2'>
    //     <img src={`/img/${chainName.toLowerCase()}.svg`} width="20px" alt={chainName} /> <div>{chainName}</div>
    //   </div>,
    // },

    {
      title: "Mã giao dịch trên chain",
      dataIndex: 'txHashOnChain',
      key: 'txHashOnChain',
      render: (txHashOnChain: {
        txHash: string;
        chainId: number
      }, chain: any) => txHashOnChain && <>
        <div className='col'>
          <div className='row'>
            <div >{chain.chainCode} <img src={`/img/${chain.chainCode.toLowerCase()}.svg`} width="20px" alt={chain.chainCode} /></div>

          </div>
          <div className=' row'>
            <Address
              address={txHashOnChain.txHash}
              link={getExplorerLink(txHashOnChain.chainId, txHashOnChain.txHash, 'transaction')}
            />
          </div>

        </div></>
    },
  ];

  useEffect(() => {
    dispatch(TRANSACTION.REQUEST(filter));
  }, [dispatch, filter]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">
              <FormattedMessage id={title} />
            </h3>
          </div>
        </div>

        <div className="card-body">
          <div className="row align-items-end mb-7">{children}</div>
          <Table loading={loading} columns={columns} dataSource={transaction.items.map((item: TransactionItem) => ({
            ...item,
            txHashOnChain: item.txHash ? {
              txHash: item.txHash,
              chainId: chains.find(chain => chain.name === item.chainCode)?.id || 0
            } : null
          }))} />
        </div>
        {transaction.totalPages > 1 && (<>
          <Pagination
            onPageChange={(page: number) => handleChange({ page })}
            page={transaction.page}
            limit={transaction.perPage}
            total={transaction.totalItems}
          />
        </>)}

      </div>
    </>
  );
};
