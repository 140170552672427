import { useEffect, useState } from "react";
import { FaIcons } from "../../helpers/FaIconHelper";
import { INFT } from "../../types/MultiTransfer";
import { Modal } from "react-bootstrap";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import Checkbox from "../../components/common/Checkbox";
import { CurrencySymbols } from "../../types/CurrencyBalance";
import { formatCurrency } from "../../helpers";
import { TListModel } from "../../types/Api";
import { TabItem } from "../../components";

import { useSelector } from "react-redux";
import { getChainsSelector } from "../../store/chain/selectors";
import { Chains } from "../../types/Chain";
import NFTDappService from "../../services/NFTDappService";
import { Loading } from "../../components/common/Loading";


const FormikNftSelect = ({
  formik,
  selectNFTs,
  selectedNFTs,
  data,
  showNFTAddNewModal,
  setShowNFTAddNewModal,
  setPermitNFTs,
  permitNFTs,
  signNFT,
  permits,
  setPermits,
  handleChange,
  loading
}: {
  formik: any,
  selectNFTs: any,
  selectedNFTs: INFT[],
  data?: TListModel<INFT>,
  showNFTAddNewModal: boolean,
  setShowNFTAddNewModal: any,
  setPermitNFTs: any,
  permitNFTs: INFT[]
  signNFT: any,
  permits: any,
  setPermits: any
  handleChange: any,
  loading: boolean

}) => {
  const [loadingSign, setLoadingSign] = useState(false);
  const onHide = () => {
    setShowNFTAddNewModal(!showNFTAddNewModal);
  }
  useEffect(() => {
    formik.setFieldValue("nftIds", selectedNFTs.join("|"));
  }, [selectedNFTs])
  return (
    <div className="mb-6 mt-5">
      <label className="form-label required">
        Chọn NFT muốn gửi
      </label>
      <div>
        <div className="row mb-3">


          <input type="email" className="form-select" aria-label="Select example" placeholder={selectedNFTs.length === 0 ? "Chọn NFT(s)" : `Đã chọn ${selectedNFTs.length}  NFT`} onClick={(e) => {

            setShowNFTAddNewModal(!showNFTAddNewModal);
          }} disabled={loading} />

        </div>
        {selectedNFTs.length > 0 && <>
          <div className='row'>
            <div className="col hover-scroll-x mh-200px pt-2">
              <div className=" d-flex gap-3 flex-wrap  ">
                {selectedNFTs?.map((nft, k) => {
                  const permited = permits.find((pn: any) => pn.tokenId === nft.tokenId);
                  const clearNft = (id: any) => {
                    selectNFTs(selectedNFTs.filter(sn => sn?.tokenId !== id));
                    if (permited) {

                      setPermits(permits.filter((sn: any) => sn?.tokenId !== id))

                      setPermitNFTs(permitNFTs.filter(sn => sn?.tokenId !== id));// case remove after sign

                    }

                  }
                  const sign = async () => {
                    if (permited) {
                      return;
                    }
                    console.log("sign", nft)
                    setLoadingSign(true);
                    await signNFT(nft);
                    setLoadingSign(false);
                  }

                  return <>
                    <div className="symbol symbol-70px border px-1 py-1 rounded ">
                      <div className="symbol-label fs-2 fw-semibold rounded" style={{

                        backgroundImage: `url('${nft.image}')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: "69px 32px"
                      }}></div>
                      <span className="symbol-badge badge badge-circle bg-danger start-100 top-0" style={{ width: "15px", height: "15px", top: "6x", left: "70% !important" }}>
                        <i className={`${FaIcons["fas fa-minus"]} text-white `} style={{ cursor: "pointer" }}
                          onClick={() => clearNft(nft.tokenId)}

                        /></span>
                      <div> <span className="fs-7 ">#{nft.tokenId}</span>
                      </div>
                      <div>
                        <img src='../img/vchain3.png' style={{ width: 15, height: 15 }} />
                        <span className="fs-6 fw-bold ">{nft.description}
                        </span>
                      </div>
                    </div >
                  </>
                })}
              </div>
            </div>

          </div>
        </>}
        {showNFTAddNewModal && <NftIssueModal {...{
          show: showNFTAddNewModal,
          onHide,
          data,
          selectNFTs,
          selectedNFTs,
          handleChange,
          signNFT,

        }} />}
      </div>


      {
        formik.touched.nftIds && formik.errors.nftIds && (
          <div className="fv-plugins-message-container invalid-feedback_error">
            <div className="fv-help-block">{formik.errors.nftIds}</div>
          </div>
        )
      }
    </div >
  )
}

export default FormikNftSelect;


const NftIssueModal = ({
  show,
  onHide,
  data,
  selectNFTs,
  selectedNFTs,
  handleChange,
  signNFT,
}: {
  show: boolean,
  onHide: any;
  data?: TListModel<INFT>;
  selectNFTs: any,
  selectedNFTs: INFT[],
  handleChange: any
  signNFT: any,
}) => {

  const chains = useSelector(getChainsSelector);


  const [selectNFTsIn, setSelectNFTsIn] = useState<INFT[]>(selectedNFTs)
  const [data1, setData] = useState<TListModel<INFT>>();
  const [loading, setLoading] = useState(false);
  const [loadingSign, setLoadingSign] = useState(false);
  const currentChainId = localStorage.getItem('chainId') ? Number(localStorage.getItem('chainId')) : Number(localStorage.getItem('fromChainId'));
  const curChain: any = chains.find((item1: any) => item1.id === currentChainId)

  const [filter, setFilter] = useState<any>({
    chainCode: curChain ? curChain.name : Chains.POLYGON,
    page: 1,
    size: 100
  });
  // console.log(curChain)
  const handleChange1 = (params: any) => {
    const currentChainId = params["chainId"] ?? Number(localStorage.getItem('fromChainId'));
    localStorage.setItem("chainId", currentChainId.toString())
    // console.log(selectedNFTs)
    selectNFTs([])
    setSelectNFTsIn([])

  };

  useEffect(() => {

    loadData();
  }, [filter])

  const loadData = async () => {
    setLoading(true);

    const data = await NFTDappService.getMyNFTs(filter);
    setData(data);
    setLoading(false);
  }

  const total = selectNFTsIn.reduce((total: number, nft) => {
    const value = nft.description.replaceAll(",", "").replaceAll(".", "");
    return total + parseInt(value);
  }, 0)
  // check more
  const handleCheckboxChange = (event: any) => {
    const _item = event.target.value;
    const item = JSON.parse(_item);
    const curChain: any = chains.find((item1: any) => item1.name === item.chainCode)

    localStorage.setItem("chainId", curChain?.id.toString())
    if (event.target.checked) {
      setSelectNFTsIn([...selectNFTsIn, item])
    } else {
      setSelectNFTsIn(selectNFTsIn.filter((selectedItem: { tokenId: any }) => selectedItem.tokenId !== item.tokenId))
      //  selectNFTs(selectedNFTs.filter((selectedItem: { tokenId: any }) => selectedItem.tokenId !== item.tokenId))
    }
  };

  const doClose = async () => {
    selectNFTs([])
    setSelectNFTsIn([])
    onHide()
  }
  const doApply = () => {
    selectNFTs(selectNFTsIn)
    onHide()
  }
  console.log("selectedNFTs", selectNFTsIn, selectedNFTs)
  return (
    <Modal
      className={clsx(
        "modal-sticky modal-sticky-lg modal-sticky-bottom-right",

      )}
      id="kt_mega_menu_modal"
      aria-hidden="true"
      tabIndex="-1"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName="modal-dialog mw-400px"
      onHide={doClose}
    >

      <div className="modal-header border-0 ">

        <h2>
          <div className="mb-1">Chọn NFT</div>
          <div className="mb-1">
            <div className="alert alert-warning d-flex align-items-center p-1 mb-2">
              <span className="svg-icon svg-icon-2hx svg-icon-warning me-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-warning"
                >
                  <path
                    opacity="0.3"
                    d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                    fill="#ffc700"
                  ></path>
                  <path
                    d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                    fill="#ffc700"
                  ></path>
                </svg>
              </span>

              <div className="d-flex flex-column fs-8">
                <span>Lưu ý: danh sách hiển thị trên chỉ gồm những VNFT được phát hành trực tiếp đến ví bạn, không phải toàn bộ NFT bạn đang sở hữu.</span>
              </div>
            </div>
          </div>
          <div className="col row">
            <div className="d-flex  gap-1 bg-secondary rounded p-1 text-center">
              {chains.map((item: any) => {

                return <>
                  {item.name !== "TRON" && (<TabItem
                    key={item}
                    label={item.name}
                    onClick={() => {
                      handleChange1({ chainId: item.id, chainCode: item.name, })
                      setFilter({ ...filter, chainCode: item.name, chainId: item.id });
                    }}
                    active={item.name === filter.chainCode}
                    activeClass="bg-primary text-white"
                  />)}
                </>
              })}
            </div>
          </div>

        </h2>
        <button onClick={onHide} className="btn btn-sm btn-icon btn-active-color-primary" style={{ position: "relative", top: "-47px" }}>
          <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
        </button>

        {/* <div style={{ position: "relative", top: "-47px" }}> <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" /></div> */}
      </div>

      <div className="modal-body ">
        {/* d-flex gap-4  mb-5 */}
        <div className=" ">
          {loading && (
            <div
              className="position-absolute top-0 bottom-0 start-0 end-0 d-flex justify-content-center"
              style={{ background: 'rgba(255, 255, 255, 0.7)' }}
            >
              <Loading className="text-primary fw-semibold" />
            </div>
          )}
          {data1?.items.length === 0 ?
            <div>
              <div className="text-center">
                <img src="../img/EmptyState.png" width={250} />
                <div>Bạn chưa có VNFT nào trong ví.</div>
              </div>
            </div> :
            <>
              <div className="col hover-scroll-x mh-100px">
                {(data1?.items.map((demon) => {
                  let selected: any = selectNFTsIn.length > 0 ? selectNFTsIn.find(item => item.tokenId === demon.tokenId && item.chainCode === demon.chainCode)
                    : selectedNFTs.find(item => item.tokenId === demon.tokenId && item.chainCode === demon.chainCode);

                  return (
                    <div
                      key={demon.tokenId}

                      className={clsx("rounded d-flex align-items-center gap-5 p-2 border h-50px px-3 w-96",
                        selected ? " border-dashed border-primary bg-primary bg-opacity-5" : "border"
                      )}

                    >

                      <div className="w-100 ">
                        <div className="d-flex gap-2  justify-content-between ">
                          <div className=" d-flex">
                            <img className="img-fluid w-100 rounded me-4" style={{
                              height: "32px", width: "49.76000213623047px"

                            }} src={demon.image} />
                            <div>#{demon.tokenId}</div>
                          </div>
                          <div
                            className="d-flex">
                            <div className="fw-bold me-4">{demon.description} {CurrencySymbols.VIC}</div>
                            <Checkbox
                              id={`check-${demon.tokenId}-${demon.chainCode}`}
                              value={JSON.stringify(demon)}
                              checked={selected ?? false}
                              onChange={(event) => handleCheckboxChange(event)}
                            >
                            </Checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }))}
              </div>
              <div className="d-flex gap-8 fs-6 justify-content-between ">
                <div className="">
                  <span className="text-uppercase opacity-50">Tổng giá trị</span> ( {selectNFTsIn.length} NFT)</div>
                <div>
                  <span className="text-uppercase opacity-50"></span> {formatCurrency(total, CurrencySymbols.VIC)}
                </div>
              </div>
              <hr className="opacity-10" />
              <div className="d-flex gap-2  justify-content-center">
                <button className="btn btn-outline btn-outline-primary px-10 " onClick={() => {
                  selectNFTs([])
                  setSelectNFTsIn([])
                }}>Bỏ Chọn</button>
                <button className="btn btn-primary px-10" onClick={doApply}>
                  {!loadingSign && <>Áp Dụng</>}
                  {loadingSign && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                      <FormattedMessage id="PleaseWait" defaultMessage="Đang tạo..." />{' '}
                      <span className="spinner-border spinner-border-sm align-middle mƒs-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </>}
        </div>
      </div>
    </Modal >
  )
}
