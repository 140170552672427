import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { json } from 'stream/consumers';
import { storageConstants } from '../types/Conts';
import { IChain } from '../types/Chain';

interface TransferIState {
  fromChain?: IChain;
  toChain?: IChain;
  // fromToken?: Token;
  // toToken?: Token;
  fromAmount: number | string;
  // toAmount: number | string;
  receiveAddress: string;
  quote: any;
  fee: any;
  slippageTolerance: number;
  rate: string;
  getConfigsFinish: boolean;
  refreshHistory: boolean;
  refreshTransferAndLiquidity: boolean;
  //transaction: SerializableTransactionReceipt;
  isFistLogin: boolean;
  // currencies?: Token[];
}

const initialState: TransferIState = {
  fromChain: undefined,
  toChain: undefined,
  // fromToken: undefined,
  // toToken: undefined,
  fromAmount: 0,
  // toAmount: undefined,
  receiveAddress: '',
  quote: undefined,
  fee: undefined,
  slippageTolerance: 5000,
  rate: '1',
  getConfigsFinish: false,
  refreshHistory: false,
  refreshTransferAndLiquidity: false,
  //transaction: undefined,
  isFistLogin: false,
  // currencies: [],
};
const transferSlice = createSlice({
  name: 'lp',
  initialState,
  reducers: {
    setFromChain: (state, { payload }: PayloadAction<IChain>) => {
      state.fromChain = payload;
      localStorage.setItem(storageConstants.KEY_FROM_CHAIN_ID, JSON.stringify(payload?.id));
    },
    setToChain: (state, { payload }: PayloadAction<IChain>) => {
      state.toChain = payload;
      localStorage.setItem(storageConstants.KEY_TO_CHAIN_ID, JSON.stringify(payload?.id));
    },
    // setFromToken: (state, { payload }: PayloadAction<Token>) => {
    //   state.fromToken = payload;
    // },
    // setToToken: (state, { payload }: PayloadAction<Token>) => {
    //   state.toToken = payload;
    // },
    setFromAmount: (state, { payload }: PayloadAction<number>) => {
      state.fromAmount = payload;
    },
    // setToAmount: (state, { payload }: PayloadAction<number>) => {
    //   state.toAmount = payload;
    // },
    setQuote: (state, { payload }: PayloadAction<any>) => {
      state.quote = payload;
    },
    setRate: (state, { payload }: PayloadAction<string>) => {
      localStorage.setItem(storageConstants.KEY_RATIO, payload);
      state.rate = payload;
    },
    setGetConfigsFinish: (state, { payload }: PayloadAction<boolean>) => {
      state.getConfigsFinish = payload;
    },
    // setTransaction: (state, { payload }: PayloadAction<SerializableTransactionReceipt>) => {
    //   state.transaction = payload;
    // },

    setReceiveAddress: (state, { payload }: PayloadAction<string>) => {
      state.receiveAddress = payload;
    },
    setIsFistLogin: (state, { payload }: PayloadAction<boolean>) => {
      state.isFistLogin = payload;
    },
    // setCurrencies: (state, { payload }: PayloadAction<Token[]>) => {
    //   state.currencies = payload;
    // },
  },
});

export const {
  setFromChain,
  setToChain,
  // setFromToken,
  // setToToken,
  setFromAmount,
  // setToAmount,
  setQuote,
  setRate,
  setGetConfigsFinish,
  //setTransaction,
  setReceiveAddress,
  setIsFistLogin,
  //setCurrencies,
} = transferSlice.actions;
export default transferSlice.reducer;
interface SwitchChainSuccessCallback {
  (id: number): void;
}
/**
 * @deprecated use web3context instead.
 * @param id
 * @param atoken
 * @param chains
 * @param switchChainSuccessCallback
 * @returns
 */
export const switchChain = async (
  id: any,
  atoken: any,
  chains: any,
  switchChainSuccessCallback: SwitchChainSuccessCallback
) => {
  const inId = Number(id);

  const providerName = localStorage.getItem(storageConstants.KEY_WEB3_PROVIDER_NAME);
  if (providerName && providerName === 'walletconnect') {
    return;
  }
  const objectChains = chains.find((item: { id: any }) => {
    return item.id === id;
  });
  try {
    if (window.clover) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${inId.toString(16)}`,
            rpcUrls: [objectChains?.rpcUrl],
            chainName: objectChains?.name,
            blockExplorerUrls: [objectChains?.explorerUrl],
            nativeCurrency: {
              name: objectChains?.name,
              symbol: objectChains?.symbol,
              decimals: 18,
            },
          },
        ],
      });
    } else {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${inId.toString(16)}` }],
      });
    }
    switchChainSuccessCallback(id);
    if (atoken) {
      localStorage.setItem(storageConstants.KEY_TO_ADD_TOKEN, JSON.stringify({ atoken, toId: inId }));
      // addChainToken(atoken);
    }
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    // const message = (switchError.message as string) ?? "";
    // const testString = "Unrecognized chain ID";
    // || message.toLowerCase().includes(testString.toLowerCase())
    if ((switchError as { code: number }).code === 4902) {
      try {
        // const chainInfo =
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${inId.toString(16)}`,
              rpcUrls: [objectChains?.rpcUrl],
              chainName: objectChains?.name,
              blockExplorerUrls: [objectChains?.explorerUrl],
              nativeCurrency: {
                name: objectChains?.name,
                symbol: objectChains?.symbol,
                decimals: 18,
              },
            },
          ],
        });
        switchChainSuccessCallback(id);
        if (atoken) {
          localStorage.setItem(storageConstants.KEY_TO_ADD_TOKEN, JSON.stringify({ atoken, toId: id }));
          // addChainToken(atoken);
        }
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
};
