import {
  GET_TOKENS_REQUEST,
  GET_TOKENS_FAILED,
  GET_TOKENS_SUCCESS,
} from "./actionTypes";
import {
  FetchTokenRequest,
  FetchTokenSuccess,
  FetchTokenSuccessPayload,
  FetchTokenFailure,
  FetchTokenFailurePayload,
} from "./types";

export const fetchTokenRequest = (): FetchTokenRequest => ({
  type: GET_TOKENS_REQUEST,
});

export const fetchTokenSuccess = (
  payload: FetchTokenSuccessPayload
): FetchTokenSuccess => ({
  type: GET_TOKENS_SUCCESS,
  payload,
});

export const fetchTokenFailure = (
  payload: FetchTokenFailurePayload
): FetchTokenFailure => ({
  type: GET_TOKENS_FAILED,
  payload,
});
