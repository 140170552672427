import { useCallback } from 'react';
import { useAppSelector } from '../store/hook';
import { IChain } from '../types/Chain';
import { useSelector } from 'react-redux';
import { getChainsSelector } from '../store/chain/selectors';

const useChain = () => {
  const chainList = useSelector(getChainsSelector);
  const getChainsList = (excudeChainId?: number): IChain[] => {
    if (!excudeChainId) {
      return chainList;
    }
    return chainList.filter((item: IChain) => {
      return item.id === excudeChainId;
    });
  };

  const getFirstChain = (network?: number, excudeChainId?: number): IChain | undefined => {
    if (!excudeChainId) {
      const res = chainList?.filter((item: IChain) => {
        if (network) {
          return item.id === network;
        } else {
          return item;
        }
      });
      console.log('reasadas', res);
      return res ? res[0] : undefined;
    }

    const res = chainList?.filter((item: IChain) => {
      return item.id !== excudeChainId;
    });
    return res ? res[0] : undefined;
  };
  const getChain = useCallback(
    (chainId: any): any => {
      let chain: any;
      chain = chainList?.find((item): any => {
        return item.id.toString() === chainId;
      });
      console.log('chainList', chain);
      return chain;
    },
    [chainList]
  );
  return { getChainsList, getFirstChain, getChain };
};
export default useChain;
