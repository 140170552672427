// export default function setupAxios(axios: any, store: any) {
//   axios.defaults.headers.Accept = 'application/json';
//   axios.interceptors.request.use(
//     (config: any) => {
//       const {
//         auth: { accessToken },
//       } = store.getState();

//       if (accessToken) {
//         config.headers.Authorization = `Bearer ${accessToken}`;
//       }

//       return config;
//     },
//     (err: any) => {
//       console.log('setup asiox', err);
//       return Promise.reject(err);
//     }
//   );
// }

import AuthHelpers from "../../helpers/AuthHelpers";

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json';
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = AuthHelpers.getAuth();
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }
      return config;
    },
    (err: any) => Promise.reject(err)
  )
}
