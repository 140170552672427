import { GET_CHAINS_REQUEST, GET_CHAINS_FAILED, GET_CHAINS_SUCCESS } from './actionTypes';
import {
  FetchChainRequest,
  FetchChainSuccess,
  FetchChainSuccessPayload,
  FetchChainFailure,
  FetchChainFailurePayload,
} from './types';

export const fetchChainRequest = (): FetchChainRequest => ({
  type: GET_CHAINS_REQUEST,
});

export const fetchChainSuccess = (payload: FetchChainSuccessPayload): FetchChainSuccess => ({
  type: GET_CHAINS_SUCCESS,
  payload,
});

export const fetchChainActive = (payload: FetchChainSuccessPayload): FetchChainSuccess => ({
  type: GET_CHAINS_SUCCESS,
  payload,
});
export const fetchChainFailure = (payload: FetchChainFailurePayload): FetchChainFailure => ({
  type: GET_CHAINS_FAILED,
  payload,
});
