import { ApiListModel } from '../types/Api';
import { TokenModel } from '../types/Token';
import AxiosInstance from './axios';

const getTokenList = async (): Promise<ApiListModel> => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  const items: TokenModel[] = [];
  try {
    const data = await AxiosInstance.get('/tokens').then((res) => res.data);
    data.items.forEach((item: any) => {
      items.push({
        address: item.address,
        name: item.name,
        symbol: item.symbol,
        decimals: 18,
        isNative: item.native,
        chainId: item.chainId,
      });
    });

    return { page: 1, perPage: 20, totalItems: 20, totalPages: 1, items: items as TokenModel[] };
  } catch (e) {
    throw e;
  }
};
const TokenService = { getTokenList };
export default TokenService;
