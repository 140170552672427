import AxiosInstance from './axios';
import { toast } from 'react-toastify';
import { buildQueryString, uuid } from '../helpers/UtilsHelpers';
import { TListModel } from '../types/Api';
import { IMultiTransfer, INFT } from '../types/MultiTransfer';
import _ from 'lodash';

//chuyen nhieu
const transferNFTs = async ({
  chainCode,
  permit,
  recipients,
  nftAddress,
  permitBatch,
}: {
  chainCode: string;
  permit: {
    deadline: number;
    v: number;
    r: string;
    s: string;
  }; //[];
  recipients: string[];
  nftAddress: string;
  permitBatch: {
    details: string[];
    spender: string;
    sigDeadline: number;
  };
}): Promise<IMultiTransfer> => {
  try {
    const url = `/nft-dapp/nfts/multi-transfer`;
    return await AxiosInstance.post(url, {
      chainCode,
      permit,
      recipients,
      nftAddress,
      permitBatch,
    }).then((res) => res.data);
  } catch (err: any) {
    console.log(`Func: transferNFTs - PARAMS: err`, err);
    toast.error(err?.response?.data?.message, {
      autoClose: 2000,
    });
    throw err;
  }
};

//tinh toan fee
const estimateFee = async ({
  chainCode,
  permits,
  recipients,
  nftAddresses,
  tokenIds,
}: {
  chainCode: string;
  permits?: {
    deadline: number;
    v: number;
    r: string;
    s: string;
  }[];
  recipients?: string[];
  nftAddresses?: string[];
  tokenIds?: number[];
}): Promise<{ feeAmount: number }> => {
  try {
    const url = `/nft-dapp/nfts/multi-transfer/estimate-fee`;

    const params = { chainCode };

    if (permits) {
      _.set(params, 'permits', permits);
    }
    if (recipients) {
      _.set(params, 'recipients', recipients);
    }
    if (nftAddresses) {
      _.set(params, 'nftAddresses', nftAddresses);
    }
    if (tokenIds) {
      _.set(params, 'tokenIds', tokenIds);
    }

    return await AxiosInstance.post(url, params).then((res) => res.data);
  } catch (err: any) {
    console.log(`Func: estimatePrice - PARAMS: err`, err);
    toast.error(err?.response?.data?.message, {
      autoClose: 2000,
    });
    throw err;
  }
};

// // danh sach nft
const getMyNFTs = async ({
  chainCode,
  page = 1,
  size = 10,
}: {
  chainCode: string;
  page?: number;
  size?: number;
}): Promise<TListModel<INFT>> => {
  try {
    const url = '/nft-dapp/nfts';
    const urlSearchParams = buildQueryString({
      chainCode,
      page,
      size,
    });
    return await AxiosInstance.get(`${url}?${urlSearchParams}`).then((res) => res.data);
  } catch (err: any) {
    console.log(`Func: getIssueHistory - PARAMS: err`, err);
    toast.error(err?.response?.data?.message, {
      autoClose: 2000,
    });
    throw err;
  }
};

const summary = async ({ chainCode }: { chainCode?: string }) => {
  try {
    const url = '/nft-dapp/nfts/summary';
    const params = chainCode ? { chainCode: chainCode } : {};
    return await AxiosInstance.get(`${url}`, { params }).then((res) => res.data);
  } catch (err: any) {
    console.log(`Func: summary - PARAMS: err`, err);
    toast.error(err?.response?.data?.message, {
      autoClose: 2000,
    });
    throw err;
  }
};

export default {
  transferNFTs,
  estimateFee,
  getMyNFTs,
  summary,
};
