import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_SETTINGS_REQUEST, DELETE_TRANSACTION, SETTINGS } from './actions';
import { getSettings, deleteTransaction } from '../../services/SettingService';
import { SettingsModel } from './types';
function* fetchSetting() {
  try {
    const response: SettingsModel = yield call(getSettings);
    yield put(SETTINGS.SUCCESS(response));
  } catch (err) {
    yield put(SETTINGS.ERROR(err));
    console.log(`Func: fetchSettingSaga - PARAMS: err`, err);
  }
}

function* blockTransaction() {
  const res: boolean = yield call(deleteTransaction);
  yield put(SETTINGS.UPDATE_SUSPEND({ suspended: res }));
}

function* settingsSaga() {
  yield takeEvery(GET_SETTINGS_REQUEST, fetchSetting);
  yield takeEvery(DELETE_TRANSACTION, blockTransaction);
}

export default settingsSaga;
