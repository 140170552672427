import { FC } from 'react';
import { useLang } from './Metronici18n';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/vi';

import viMessages from './messages/vi.json';
import enMessages from './messages/en.json';
import { WithChildren } from '../helpers/react18MigrationHelpers';

const allMessages = {
  vi: viMessages,
  en: enMessages,
};

const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages} onError={() => { }}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
