import { GET_CHAINS_REQUEST, GET_CHAINS_FAILED, GET_CHAINS_SUCCESS } from './actionTypes';
import { ChainActions, ChainState } from './types';

const initialState: ChainState = {
  pending: false,
  chains: [],

  error: null,
};

const reducer = (state = initialState, action: ChainActions) => {
  switch (action.type) {
    case GET_CHAINS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_CHAINS_SUCCESS:
      return {
        ...state,
        pending: false,
        chains: action.payload.chains,
        error: null,
      };

    case GET_CHAINS_FAILED:
      return {
        ...state,
        pending: false,
        chains: [],
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
