import { lazy, Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { I18nProvider } from './i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from './layout/core';
import { MasterInit } from './layout/MasterInit';
import AuthInit from './store/auth/AuthInit';
import { GlobalDebug } from './utils/function';
import { GoogleOAuthProvider } from '@react-oauth/google';
import appConfig from './appConfig';

const App = () => {
  useEffect(() => {
    process.env.NODE_ENV === 'production' && GlobalDebug(false);
  }, []);

  console.log('I am just another dummy console log,  suppose to be suppressed 🙂');

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <GoogleOAuthProvider clientId={appConfig.googleClientId}>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </GoogleOAuthProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
