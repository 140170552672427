import { Table as TableBootstrap } from 'react-bootstrap';
import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Loading } from '../common/Loading';
import { NoData } from '../NoData';

interface ColumnsType {
  align?: 'start' | 'end' | 'center';
  className?: string;
  dataIndex: string;
  title: ReactNode;
  width?: string | number;
  render?: (value: any, record: any) => React.ReactNode;
}

interface IProps {
  loading?: boolean;
  showHeader?: boolean;
  bordered?: boolean;
  borderless?: boolean;
  hover?: boolean;
  responsive?: boolean | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  columns: ColumnsType[];
  dataSource: Record<string, any>[];
}

export const Table = (props: IProps) => {
  const { showHeader = true, columns, dataSource, loading, ...rest } = props;
  return (
    <>
      <div className="position-relative">
        {loading && (
          <div
            className="position-absolute top-0 bottom-0 start-0 end-0 d-flex justify-content-center"
            style={{ background: 'rgba(255, 255, 255, 0.7)' }}
          >
            <Loading className="text-primary fw-semibold" />
          </div>
        )}
        <TableBootstrap {...rest} className="border-top">
          <colgroup>
            {columns.map(({ width }) => {
              if (!width) return null;
              return <col style={{ width }} />;
            })}
          </colgroup>
          {showHeader && (
            <thead>
              <tr>
                {columns.map(({ align = 'start', dataIndex, title, className }) => (
                  <th className={clsx(className, `text-${align}`)} key={dataIndex}>
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {!dataSource.length && (
              <tr>
                <td colSpan={columns.length}>
                  <NoData />
                </td>
              </tr>
            )}
            {dataSource.map((data, i) => (
              <tr key={i}>
                {columns.map(({ align = 'start', dataIndex, className, render }) => (
                  <td className={clsx(className, `text-${align}`)} key={dataIndex}>
                    {render ? <>{data[dataIndex] && render(data[dataIndex], data)}</> : data[dataIndex]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </TableBootstrap>
      </div>
    </>
  );
};
