import moment from 'moment';
import 'moment/locale/vi';
import { ShortNumber as FSN } from '@lytieuphong/short-number';
import { getConfig } from '../i18n/Metronici18n';
import { CurrencyChain, CurrencySymbols } from '../types/CurrencyBalance';

const locale = 'en-US';

export function formatNumber(number: number | bigint | string | undefined, maximumFractionDigits: number = 2): string {
  if (!number) return '0';

  return new Intl.NumberFormat(locale, {
    maximumFractionDigits,
    // @ts-ignore
    roundingMode: 'floor',
  }).format(parseFloat(number as string));
}

function roundDownSignificantDigits(number: number, decimals: number) {
  let significantDigits = parseInt(number.toExponential().split('e-')[1]) || 0;
  let decimalsUpdated = (decimals || 0) + significantDigits - 1;
  decimals = Math.min(decimalsUpdated, number.toString().length);

  return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function formatCurrencyOld(value: number | bigint | string, currency?: string, hideCurrency = false): string {
  let result = '';
  if (typeof value === 'number' && value >= 1) {
    if (currency?.includes(CurrencySymbols.VIC)) {
      result = Intl.NumberFormat(locale, { maximumSignificantDigits: 2 }).format(
        roundDownSignificantDigits(value as number, 2)
      );
    }
    result = Intl.NumberFormat(locale, { maximumSignificantDigits: 8 }).format(
      roundDownSignificantDigits(value as number, 8)
    );
  } else {
    result = Intl.NumberFormat(locale, { maximumSignificantDigits: 6 }).format(
      roundDownSignificantDigits(value as number, 6)
    );
  }
  return hideCurrency || !currency ? result : result + ' ' + currency;
}

export function formatCurrency(
  value: number | bigint | string,
  currency = '',
  hideCurrency = false,
  maximumFractionDigits = 4
): string {
  const _currency = !hideCurrency ? ` ${currency}` : '';
  const isVNDT = currency.includes(CurrencySymbols.VIC.toString());

  if (parseFloat(value.toString()) <= 0.9999999999999999) {
    return formatNumber(value, maximumFractionDigits) + _currency;
  }

  return formatNumber(value, isVNDT ? 0 : maximumFractionDigits) + _currency;
}

export function currencyFormat(num: any, ext: number = 0) {
  return num !== null
    ? Number(num)
        .toFixed(ext)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    : 0;
}

type Type = 'Date' | 'Time';

export const showDateTime = (date: any, type?: Type) => {
  let dateString;

  switch (type) {
    case 'Date':
      dateString = moment(date).format('DD/MM/YYYY');
      break;
    case 'Time':
      dateString = moment(date).format('HH:mm:ss');
      break;
    default:
      dateString = moment(date).format('MM/DD/YYYY HH:mm:ss');
      break;
  }

  return dateString;
};

export const showDateTimeUTC = (date: any, type?: Type) => {
  let dateString;

  switch (type) {
    case 'Date':
      dateString = moment(date).utc().format('DD/MM/YYYY');
      break;
    case 'Time':
      dateString = moment(date).utc().format('HH:mm:ss');
      break;
    default:
      dateString = moment(date).utc().format('MM/DD/YYYY HH:mm:ss');
      break;
  }

  return dateString;
};

export function showTime(timestamp: any) {
  var days = moment.utc(timestamp).fromNow();
  return days;
}

export const calculateLeftTime = (timestamp: any) => {
  moment.locale('vi');
  var days = moment.utc(timestamp).add(5, 'days').fromNow();
  console.log('days', days);
  return days;
};

export function ellipseAddress(address: string = '', width: number = 10): string {
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

export function capitalizeTxt(txt: any) {
  return txt.charAt(0).toUpperCase() + txt.slice(1); //or if you want lowercase the rest txt.slice(1).toLowerCase();
}

export function toFixed(x: any) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
}

export const formatShortNumber = (num: number): string | number => {
  return FSN(num);
};

export const getChainColor = (chain: string) => {
  var classS = 'text-info fw-bolder';
  if (chain === 'VCHAIN') {
    classS = 'text-primary fw-bolder';
  } else if (chain === CurrencyChain.POLYGON) {
    classS = 'text-success fw-bolder';
  } else if (chain === 'BINANCE') {
    classS = 'text-warning fw-bolder';
  }
  return classS;
};
