import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import TransactionService from '../../../services/TransactionService';
import { IFilterTransaction } from '../../../store/transaction/types';
import { useAuth } from '../../../store/auth';

interface IProps {
  filter: IFilterTransaction
  csvData: any[];
  fileName?: string;
  children: React.ReactNode | React.ReactChild;
}

export const ExportCSV = (props: IProps) => {
  const { csvData, fileName = new Date().toJSON(), children } = props;
  const { auth } = useAuth();
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportExcel = async () => {
    await TransactionService.exportExcel(props.filter, auth?.token || "");
  }

  return <div onClick={exportExcel}>{children}</div>;
};
