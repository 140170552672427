import clsx from "clsx";
import { ReactNode } from "react";

const TabItem = ({
  label,
  className,
  onClick,
  active,
  activeClass = 'bg-secondary',
}: {
  label: ReactNode;
  onClick: () => void;
  active?: boolean;
  className?: string;
  activeClass?: string;
}) => {
  return (
    <div
      className={clsx('border-0 user-select-none px-5 py-2 rounded fw-semibold cursor-pointer', className, {
        [activeClass]: active,
      })}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default TabItem