import React from 'react';
import { useIntl } from 'react-intl';

import { toAbsoluteUrl } from '../helpers';
type Props = {
  className?: String;
  text?: string;
};

const NoData: React.FC<Props> = ({ className, text }) => {
  const intl = useIntl();

  let content = text || intl.formatMessage({ id: 'NoData' });
  return (
    <div className={`no-data text-center ${className}`}>
      <div>
        <img width="30%" src={toAbsoluteUrl('/img/svg/blank.svg')} />
      </div>
      <div className="content text-muted">{content}</div>
    </div>
  );
};
export { NoData };
