export interface CurrencyBalance {
  id: string;
  balance: number;
  walletId: string;
  chainId: number;
  chainName: string;
  symbol: string;
  tokenAddress: string;
}

export enum CurrencySymbols {
  USDT = 'USDT',
  VIC = 'VIC',
}

export enum CurrencyChain {
  POLYGON = 'POLYGON',
  BINANCE = 'BINANCE',
}
