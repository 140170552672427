export enum TxTypes {
  ALL = 'ALL',
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  TRANSFER = 'TRANSFER',
  FEE = 'FEE',
  USER_PAY = 'USER_PAY',
  ISSUED_NFT = 'ISSUED_NFT',
  CONSUMED_NFT = 'CONSUMED_NFT',
}

export enum TxTypeClassNames {
  DEPOSIT = 'badge badge-light-warning text-uppercase',
  WITHDRAW = 'badge badge-light-success text-uppercase',
  TRANSFER = 'badge badge-light-info text-muted text-uppercase',
  FEE = 'badge badge-light-danger text-uppercase',
  USER_PAY = 'badge badge-light-success text-uppercase',
  ISSUED_NFT = 'badge badge-light text-uppercase',
  CONSUMED_NFT = 'badge badge-light-info text-info text-uppercase',
  TRANSFERRED_NFT = 'badge badge-light-warning text-uppercase',
}

export enum TxStatus {
  ALL = 'ALL',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export enum TxStatusClassNames {
  CREATED = 'badge badge-light-warning text-uppercase',
  FAILED = 'badge badge-light-danger text-uppercase',
  COMPLETED = 'badge badge-light-success text-uppercase',
}

export interface ITransaction {
  address: string;
  balance: number;
  changeAmount: number;
  cashFlowDirection: string;
  createdAt: string;
  chainId: number;
  chainName: string;
  fromAddress: string;
  toAddress: string;
  tokenAddress: string;
  tokenType: string;
  tokenSymbol: string;
  txHash: string;
  type: string;
}
