import { FC, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../layout/MasterLayout";
import { WithChildren } from "../helpers/react18MigrationHelpers";

import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../assets/ts/_utils";
import PageTransactionHistory from "../pages/transaction/PageTransactionHistory";
import { Error404 } from "../components/errors/Error404";
import PageSendNFT from "../pages/nft/PageSendNFT";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/nft" />} />

        <Route
          path="/nft"
          element={
            <PageSendNFT />
          }
        />
        <Route
          path="/transaction-history"
          element={
            <SuspensedView>
              <PageTransactionHistory />
            </SuspensedView>
          }
        />

        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes, SuspensedView };
