export const storageConstants = {
  KEY_IS_CLOVER_WALLET: 'isClover',
  KEY_ADD_LP_TRANSACTION_HASH: 'addLpTransactionHash',
  KEY_LP_LIST: 'LpList',
  KEY_TRANSFER_LIST_JSON: 'transferListJson',
  KEY_CHAIN_NAME: 'chainName',
  KEY_TAB_KEY: 'tabkey',
  KEY_FROM_CHAIN_ID: 'fromChainId',
  KEY_SELECTED_INDEX: 'selectedIndex',
  KEY_TO_CHAIN_ID: 'toChainId',
  KEY_TO_ADD_TOKEN: 'ToAddToken',
  KEY_HAS_REWARD_EVENTS: 'hasRewardEvents',
  KEY_RATIO: 'ratio',
  KEY_SELECTED_TOKEN_SYMBOL: 'selectedTokenSymbol',
  KEY_SELECTED_TO_TOKEN_SYMBOL: 'selectedToTokenSymbol',
  KEY_WEB3_PROVIDER_NAME: 'web3providerName',
  KEY_CLEAR_TAG: 'clearTag',
  KEY_SOURCE_FROM_URL: 'sourceFromUrl',
};
