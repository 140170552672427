import { AuthModel } from '../types/Auth';

const AUTH_LOCAL_STORAGE_KEY = 'cxp-auth';

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;

    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    localStorage.removeItem(`persist:${AUTH_LOCAL_STORAGE_KEY}`);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
  }
};

const isTokenExpired = (token: string) => {
  try {
    const jwtPayload = JSON.parse(window.atob(token.split('.')[1]));
    return Date.now() >= jwtPayload.exp * 1000;
  } catch (error) {
    console.log('isTokenExpired', error);
    return false;
  }
};

const AuthHelpers = { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, isTokenExpired };
export default AuthHelpers;
