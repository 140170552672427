import * as Yup from "yup";
import { useIntl } from "react-intl";
import Web3 from "web3";
export const MyYup = function () {
  const intl = useIntl();
  Yup.addMethod(Yup.StringSchema, "isAddress", function (args) {
    // console.log("isAddress", formats, parseStrict);

    return this.test("is-address", "InvalidAddress", function (value) {
      const { path, createError } = this;
      return (
        Web3.utils.isAddress(value ?? "") ||
        createError({
          path,
          message: intl.formatMessage({ id: "Form.validation.invalidAddress" }),
        })
      );
    });
  });
  return Yup;
};
export const YupI18n = function () {
  const intl = useIntl();
  Yup.setLocale({
    mixed: {
      default: intl.formatMessage({ id: "Form.validation.fieldInvalid" }),
      required: (props) => {
        return intl.formatMessage(
          { id: "FORM.validation.required" },
          { field: intl.formatMessage({ id: `FORM.${props.path}` }) }
        );
      },
    },
    string: {
      min: ({ min }) =>
        intl.formatMessage({ id: "FORM.validation.minLength" }, { value: min }),
      max: ({ max }) =>
        intl.formatMessage({ id: "FORM.validation.maxLength" }, { value: max }),
      length: ({ length }) =>
        intl.formatMessage({ id: "FORM.validation.Length" }, { value: length }),
      email: intl.formatMessage({ id: "FORM.validation.emailFormat" }),
    },
    number: {
      min: ({ min }) =>
        intl.formatMessage({ id: "FORM.validation.min" }, { value: min }),
      max: ({ max }) =>
        intl.formatMessage({ id: "FORM.validation.max" }, { value: max }),
      moreThan: ({ more }) =>
        intl.formatMessage({ id: "FORM.validation.more" }, { value: more }),
      lessThan: ({ less }) =>
        intl.formatMessage({ id: "FORM.validation.less" }, { value: less }),
    },
  });
};
