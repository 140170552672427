import { addDays, addMonths, addYears, isSameDay, endOfDay } from 'date-fns';
import { Range } from 'react-date-range';

export const defineds = {
  startOfToday: addDays(new Date(), -1),
  endOfToday: endOfDay(new Date()),
  oneWeek: addDays(new Date(), -6),
  oneMonth: addMonths(new Date(), -1),
  threeMonth: addMonths(new Date(), -3),
  sixMonth: addMonths(new Date(), -6),
  oneYear: addYears(new Date(), -1),
};

const staticRangeHandler = {
  range: {},
  isSelected(range: any) {
    // @ts-ignore
    const definedRange = this.range();
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
  },
};

export function createStaticRanges(ranges: any) {
  return ranges.map((range: Range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: '7 ngày gần nhất',
    range: () => ({
      startDate: defineds.oneWeek,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Tháng qua',
    range: () => ({
      startDate: defineds.oneMonth,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '3 tháng gần nhất',
    range: () => ({
      startDate: defineds.threeMonth,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '6 tháng gần nhất',
    range: () => ({
      startDate: defineds.sixMonth,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Năm qua',
    range: () => ({
      startDate: defineds.oneYear,
      endDate: defineds.endOfToday,
    }),
  },
]);

export type SelectRange = 'oneDate' | 'oneWeek' | 'oneMonth' | 'oneYear' | 'all';

export const selectDateRange = (key: SelectRange) => {
  const dates = {
    oneDate: {
      startDate: addDays(new Date(), -1),
      endDate: new Date(),
      key: 'selection',
      active: 'oneDate',
    },
    oneWeek: {
      startDate: addDays(new Date(), -6),
      endDate: new Date(),
      key: 'selection',
      active: 'oneWeek',
    },
    oneMonth: {
      startDate: addMonths(new Date(), -1),
      endDate: new Date(),
      key: 'selection',
      active: 'oneMonth',
    },
    oneYear: {
      startDate: addYears(new Date(), -1),
      endDate: new Date(),
      key: 'selection',
      active: 'oneYear',
    },
    all: {
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
      active: 'all',
    },
  };

  return dates[key as keyof typeof dates];
};
