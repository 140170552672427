import { createContext, useContext, useEffect, useState } from "react";
import { JsonRpcProvider } from '@ethersproject/providers'; // InfuraProvider,
import { useAuth } from "../store/auth";
import { connectWeb3 } from "../services/SwapServices";
import { BigNumber, ethers, utils } from 'ethers';
import { useSelector } from "react-redux";
import { getChainsSelector } from "../store/chain/selectors";
import { IChain } from "../types/Chain";
import AuthHelpers from "../helpers/AuthHelpers";

const WalletContext = createContext<
  Partial<{
    account: string,
    provider: JsonRpcProvider | ethers.providers.Web3Provider | any | undefined;
    signer: any,
    values: any;
    loadWallets?: any;
  }>
>({});

const WalletProvider = (props: any) => {

  const auth = useAuth();
  const chains = useSelector(getChainsSelector);
  const [account, setaccount] = useState('');
  const [provider, setProvider] = useState<any>();
  const [adapter, setAdapter] = useState<any>();

  const [signer, setSigner] = useState<any>();

  const [values, setValues] = useState<any>({
    address: '',
    addressHex: '',
    isConnected: false,
    chainId: null,
    chain: null,
    isUnsupported: false,
  });

  const getCurrentWeb3 = async () => {
    const { signer, provider } = await connectWeb3();


    console.log(provider)
    if (provider) {
      if (provider?.provider) {
        setProvider(provider)
        setSigner(signer)
        const chainId: any = await (await provider.getNetwork()).chainId;
        // console.log("getCurrentWeb3", (await provider.getNetwork()).chainId)
        localStorage.setItem('fromChainId', chainId.toString());
      }
    }
    return { provider, signer }
  }

  const chainMetaMaskChain = () => {

    const ethereum = window.ethereum;
    console.log("ethereum", ethereum)
    if (ethereum) {
      ethereum.on('accountsChanged', function (accounts: any[]) {
        console.log('[provider] accountChanged', accounts, values);

        const address: string = Array.isArray(accounts) ? accounts[0] : accounts;

        _updateChainData({ chainId: values.chainId, address });
        window.location.reload()
      });

      ethereum.on('chainChanged', function (chainId: any) {
        console.log(chainId)

        const temp = chainId?.chainId ?? chainId;
        const _chainId = utils.isHexString(temp) ? BigNumber.from(temp).toNumber() : temp;
        localStorage.setItem('fromChainId', _chainId);
        console.log('[provider] onChain change', chainId, _chainId);

        _updateChainData({ chainId: _chainId });
      });
    }


  }
  useEffect(() => {
    getAndSetAccount();
    getCurrentWeb3()
  }, [account]);

  useEffect(() => {
    if (provider) {
      chainMetaMaskChain()
    }
    // 

  }, [values.chainId]);


  useEffect(() => {
    if (account) {
      autoLogout()

    }
  }, [account, auth?.currentUser])
  const autoLogout = async () => {
    console.log("account vo day nha", auth?.currentUser?.address, "=====", account)
    if (auth?.currentUser?.address != undefined && auth?.currentUser?.address !== account) {
      AuthHelpers.removeAuth()
    }
  }
  const findChainById = (chainId: number) => {
    const foundChain: any = chains.find((item) => item.id === chainId);
    return foundChain;
  };
  const _updateChainData = (args: {
    chainId?: number | any;
    address?: string;
    network?: string;
    addressHex?: string;
    connected?: boolean;
    provider?: any;
    adapter?: any;
    singer?: any
  }) => {
    const foundChain: IChain = findChainById(args?.chainId);
    console.log(args, values);
    if (!args.chainId && !args.address) {
      setValues({ ...values, chain: null, chainId: null, isUnsupported: false, address: null, network: null });
      return;
    }

    setProvider(args.provider);
    setAdapter(args.adapter);
    setSigner(args.singer)
    const newData = {
      ...values,
      chain: foundChain,
      chainId: args.chainId ?? values.chainId,
      address: args.address ?? values.address,
      addressHex: args.addressHex ?? values.addressHex,
      isUnsupported: args.chainId ? !foundChain : values.isUnsupported,
      network: args.network ?? values.network,
      isConnected: args.connected ?? false,
    };
    // console.log('connect new data', values, args, newData);
    setValues(newData);
  };

  const getAndSetAccount = async () => {
    try {

      await window.ethereum.request({ method: 'eth_requestAccounts' });
      window.ethereum.on('accountsChanged', function (accounts: any[]) {
        const address: string = Array.isArray(accounts) ? accounts[0] : accounts;
        setValues({ address: address })
        setaccount(address);
        _updateChainData({ chainId: values.chainId, address });
        //  window.location.reload()
      });
    } catch (e) {
      console.log(e)
      // Alert.error('Đăng nhập bị từ chối trên Metamask của bạn. Hãy kiểm tra lại!');
      return e
    }
  }

  return (
    <WalletContext.Provider
      value={{ account, provider, signer, values }}
    >
      {props.children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
export const useWallets = () => useContext(WalletContext);