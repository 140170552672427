import { ChangeEvent, CSSProperties } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

type OptionValue = string;

type Option<Type extends OptionValue> = {
  value: Type;
  label: string;
};

type SelectProps<Type extends OptionValue> = {
  options: Option<Type>[];
  name: string;
  onChange(val: any): void;
  className?: string;
  style?: CSSProperties;
};

function Select<Type extends OptionValue>({
  options,
  name,
  onChange,
  className,
  style,
  ...restProps
}: SelectProps<Type>) {
  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    onChange(event.target.value);
  }

  return (
    <select
      value={name}
      onChange={handleChange}
      className={clsx('form-select', className)}
      style={style}
      {...restProps}
    >
      {options.map((option) => (
        <option key={option.label} value={option.value}>
          <FormattedMessage id={option.label} />
        </option>
      ))}
    </select>
  );
}
export { Select };
