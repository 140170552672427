import { chain } from 'lodash';
import { RootState } from '../setup';
import { getChainsSelector } from '../store/chain/selectors';
import { getCurrenciesSelector } from '../store/currency/selectors';
import { getTokensSelector } from '../store/token/selectors';
import { IChain } from '../types/Chain';
import { TokenModel } from '../types/Token';
import { Currency } from '../types/Currency';

export function getExplorerLink(chainId: number | undefined, data: string, type: string): string {
  if (!chainId) {
    return '';
  }
  const chain = getChain(chainId);
  if (!chain) {
    return '';
  }
  let res = getExplorerUrl(chain.explorer, type, data);

  return res;
}

export function getExplorerUrl(explorer: string, type: string, data: string): string {
  let out = '';
  switch (type) {
    case 'transaction':
      out = `${explorer}/tx/${data}`;
      break;
    case 'block':
      out = `${explorer}/block/${data}`;
      break;
    case 'token':
      out = `${explorer}/token/${data}`;
      break;
    case 'address':
      out = `${explorer}/address/${data}`;
      break;
    default:
      break;
  }
  return out;
}

// /**
//  * @deprecated Should use getExplorerLink() instread.
//  * @param chainName
//  * @param data
//  * @param type
//  * @returns
//  */
export function getExplorerLinkChainName(chainName: string | undefined, data: string, type: string): string {
  if (!chainName) {
    return '';
  }
  const chain = getChainName(chainName);
  // console.log(chain);
  if (!chain) {
    return '';
  }

  let res = getExplorerUrl(chain.explorer, type, data);

  return res;
}

export function getChain(chainId: number): IChain | null | undefined {
  const store = getReduxState();
  const chains = getChainsSelector(store);
  if (!chains.length) {
    return null;
  }
  const chain = chains.find((item) => item.id === chainId);

  return chain;
}

export function getChainName(chainName: string): IChain | null | undefined {
  const store = getReduxState();
  const chains = getChainsSelector(store);
  if (!chains.length) {
    return null;
  }
  const chain = chains.find((item) => item.name === chainName);

  return chain;
}
export function getTokenByChain(chainId: number | undefined): TokenModel | undefined {
  if (!chainId) {
    return undefined;
  }
  const store = getReduxState();
  const tokens = getTokensSelector(store);
  if (!tokens.length) {
    return undefined;
  }

  const token: any = tokens.filter((item: { chainId: number }) => item.chainId === chainId);
  // console.log("getTokenByChain",token)
  return token;
}

export function getToken(chainId: number | undefined, address: string): TokenModel | undefined {
  if (!chainId || !address) {
    return undefined;
  }
  const store = getReduxState();
  const tokens = getTokensSelector(store);
  if (!tokens.length) {
    return undefined;
  }
  // console.log("getToken",address,tokens)
  const token = tokens.find((item: { chainId: number; address: string }) => {
    if (item.chainId === chainId && item.address.toLowerCase() === address.toLocaleLowerCase()) {
      return item;
    } else {
      // console.log('test token find', item.chainId, chainId, item.address.toLowerCase(), address.toLocaleLowerCase());
      return false;
    }
  });

  return token;
}

export function getCurrency(currencyId: string): Currency | undefined {
  if (!currencyId) {
    return undefined;
  }
  const store = getReduxState();
  const items = getCurrenciesSelector(store);
  if (!items.length) {
    return undefined;
  }
  const res = items.find((item: { id: string }) => {
    if (item.id === currencyId) {
      return item;
    } else {
      // console.log('test token find', item.chainId, chainId, item.address.toLowerCase(), address.toLocaleLowerCase());
      return false;
    }
  });

  return res;
}
export const getReduxState: () => RootState = () => require('../store/Store').default.getState();
