import React from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

type Props = {
  className?: string;
  text?: string;
};

const Loading: React.FC<Props> = ({ text, className }) => {
  return (
    <span
      className={clsx(
        "indicator-progress d-flex justify-content-center align-items-center",
        className
      )}
    >
      <span className="spinner-border spinner-border-sm align-middle me-2"></span>
      {text ? text : <FormattedMessage id="Loading" />}
    </span>
  );
};
export { Loading };
