import { ApiListModel } from '../types/Api';
import { Currency } from '../types/Currency';
import AxiosInstance from './axios';

const getCurrencyBalance = async (id: string): Promise<number | null> => {
  const url = `/assets/${id}/balance`;
  const data = await AxiosInstance.get(url)
    .then((res) => res.data)
    .catch((e) => {
      console.log('[API] can not get asset balance', e);
      return null;
    });

  return data.balance ?? 0;
};
const getCurrencyList = async (): Promise<ApiListModel> => {
  const items: Currency[] = [];
  const response = await AxiosInstance.get('/assets?page=1&size=20&sort=chainId,asc&sort=typeToken,asc')
    .then((res) => res.data)
    .catch((e) => {
      console.log('[API] Can not get merchant balances', e);
      return null;
    });
  if (response && response.items.length) {
    response.items.forEach((item: any) => {
      items.push({
        id: item.id,
        symbol: item.typeToken,
        balance: 0,
        balanceAvailable: 0,
        balanceOnHold: 0,
        minAccountBalance: item.configs.minAccountBalance,
        maxAccountBalance: item.configs.maxAccountBalance,
        minTransfer: item.configs.minTransfer,
        maxTransfer: item.configs.maxTransfer,
        coldWalletAddress: item?.configs?.coldWalletAddress,
        token: {
          address: item.addressToken,
          tokenType: item.typeToken,
          symbol: item.typeToken,
          name: item.typeToken,
        },
        nativeBalance: {
          balance: item.wallet.coinBalance ?? 0,
          symbol: item.wallet.coinSymbol,
        },
        chain: {
          id: item.chainId,
          name: item.wallet.chainName,
        },
        wallet: {
          id: item.wallet.id,
          address: item.wallet.address,
          feePercent: item.wallet.configs.feePercent,
        },
      });
    });
  }
  return {
    page: 1,
    totalItems: items.length,
    totalPages: 1,
    perPage: 20,
    items: items,
  };
};

const getCurrencyHistory = async (page: number, pageSize?: number, params?: any) => {
  let filterString = '';
  if (params) {
    // let filters = new URLSearchParams(params);
    // console.log("filters", filters);
    for (const [key, value] of params.entries()) {
      //  console.log(`getCurrencyHistory ${key}, ${value} {}`);
      if (value) {
        if (filterString !== '') {
          filterString += ` and `;
        }
        filterString += `${key}:'${value}'`;
      }
    }
  }

  let url =
    `/wallets/balance-changes?page=${page}&size=${pageSize}${filterString ? '&filter=' + filterString : ''}` +
    `&sort=createdAt,DESC`;
  try {
    const res = await AxiosInstance.get(url).then((res) => res.data);
    const rawData = res?.items;
    const items: any[] = [];
    if (rawData) {
      rawData.forEach((item: any) => {
        items.push({
          id: '',
          fromAddress: item.fromAddress,
          toAddress: item.toAddress,
          chain: {
            chainId: item.chainId,
            name: item.chainName,
          },
          transactionType: item.type,
          txHash: item.txHash,
          token: {
            address: item.tokenAddress,
            symbol: item.tokenType,
            icon: '',
          },
          amount: item.changeAmount,
          type: item.cashFlowDirection,
          balance: item.balance,
          createdAt: item.createdAt,
        });
      });
    }
    return {
      page: res.page,
      size: res.perPage,
      totalItems: res.totalItems,
      totalPages: res.totalPages,
      items: items,
    };
  } catch (e) {
    console.error('[API] Can not get transaction', e);
    return null;
  }
};

const getAddRemoveLiquidityPool = async (page: number, pageSize?: number, params?: any) => {
  let filterString = '';
  if (params) {
    // let filters = new URLSearchParams(params);
    // console.log("filters", filters);
    for (const [key, value] of params.entries()) {
      if (value) {
        //   if (key === 'chainId') {
        filterString += `&${key}=${value}`;
        // }

        // if (key === 'tokenAddress') {
        //   filterString += `&tokenAddress=${value}'`;
        //  }
      }
    }
    // console.log(filterString);
  }

  let url =
    `/wallets/add-remove-liquidity-pool?page=${page}&size=${pageSize}${filterString ? filterString : ''}` +
    `&sort=createdAt,DESC`;
  console.log(`getCurrencyHistory ${url}{}`);
  try {
    const res = await AxiosInstance.get(url).then((res) => res.data);
    const rawData = res?.items;
    const items: any[] = [];
    if (rawData) {
      rawData.forEach((item: any) => {
        items.push({
          id: '',
          fromAddress: item.createdBy,
          toAddress: item.toAddress,
          chain: {
            chainId: item.chainId,
            name: item.chainName,
          },
          transactionType: item.type,
          txHash: item.txHash,
          token: {
            address: item.tokenAddress,
            symbol: item.tokenType,
            icon: '',
          },
          amount: item.changeAmount,
          type: item.cashFlowDirection,
          balance: item.balance,
          createdAt: item.createdAt,
        });
      });
    }
    return {
      page: res.page,
      size: res.perPage,
      totalItems: res.totalItems,
      totalPages: res.totalPages,
      items: items,
    };
  } catch (e) {
    console.error('[API] Can not get transaction', e);
    return null;
  }
};

/**
 * @predecated true
 * @param walletId
 * @param currencyId
 * @param formData
 * @returns
 */
// const withdraw = async (walletId: string, currencyId: string, formData: CurrencyFormValues) => {
//   let url = `/wallets/${walletId}/transfer/${currencyId}`;
//   try {
//     const data = await AxiosInstance.put(url, { walletId, assetId: currencyId, ...formData }).then((res) => res.data);

//     return {
//       status: true,
//       msg: 'Success',
//       data,
//     };
//   } catch (e: any) {
//     console.log('[API] Can not put data', e);
//     let msg = e.message;
//     if (e.response && e.response.data && e.response.data.message) {
//       msg = e.response.data.message;
//     }
//     return {
//       status: false,
//       msg,
//       data: e?.response?.data,
//     };
//   }
// };

/**
 * Make a request to withdraw my money from system to my wallet. Require to multi sign before transfer.
 *
 * @param walletId
 * @param currencyId
 * @param formData
 * @returns
 */
const requestWithdraw = async (currencyId: string, formData: any) => {
  let url = `/pool/withdrawal`;
  console.log('formData', formData);
  try {
    const data = await AxiosInstance.post(url, {
      assetId: currencyId,
      ...formData,
    }).then((res) => res.data);

    return {
      status: true,
      msg: 'Success',
      data,
    };
  } catch (e: any) {
    console.log('[API] Can not put data', e);
    let msg = e.message;
    if (e.response && e.response.data && e.response.data.message) {
      msg = e.response.data.message;
    }
    return {
      status: false,
      msg,
      data: e?.response?.data,
    };
  }
};

// const getWithdrawRequestDetail = async (chainId: string, txIndex: string): Promise<any> => {
//   if (!chainId || !txIndex) {
//     return null;
//   }

//   let url = `/withdrawal/${chainId}/${txIndex}`;
//   try {
//     const data: any = await AxiosInstance.get(url).then((res) => res.data);
//     const res: IWithdrawRequest = {
//       id: data.submissionId,
//       status: data.status,
//       data: {
//         amount: data.amount,
//         content: data.content,
//         recipientAddress: data.recipient,
//         tokenType: data.tokenType,
//         tokenAddress: data.tokenAddress,
//         chainId: data.chainId,
//         chainName: data.chainName,
//       },
//       submissions: [
//         {
//           tokenType: data.tokenType,
//           tokenAddress: data.tokenAddress,
//           chainId: data.chainId,
//           chainName: data.chainName,
//           txIndex: data.txIndex,
//           rawTransaction: data.rawTransaction,
//           status: data.status,
//           createdAt: data.createdAt,
//           createdBy: data.createdBy,
//           updatedAt: data.updatedAt,
//           updatedBy: data.updatedBy,
//           numConfirmationsRequired: data.numConfirmationsRequired,
//           confirmations: data.confirmations,
//           owners: [],
//           submitTxHash: data.submitTxHash,
//           executeTxHash: data.executeTxHash,
//           totalConfirmed: data.totalConfirmed,
//         },
//       ],
//       createdAt: data.createdAt,
//       createdBy: data.createdBy,
//       updatedAt: data.updatedAt,
//       updatedBy: data.updatedBy,
//     };
//     return res;
//   } catch (e: any) {
//     console.log('[API] Can not get data', e);
//     let msg: string = e.message;
//     if (e.response && e.response.data && e.response.data.message) {
//       msg = e.response.data.message;
//     }
//     throw e;
//   }
// };

// const getWithdrawRequests = async (page: number, pageSize: number, params?: any): Promise<ApiListModel> => {
//   let filterString = '';
//   if (params) {
//     // let filters = new URLSearchParams(params);
//     // console.log("filters", filters);
//     for (const [key, value] of params.entries()) {
//       console.log(`${key}, ${value}`);
//       if (value) {
//         filterString += `${key}:'${value}'`;
//       }
//     }
//   }

//   let url =
//     `/withdrawal/pending-submissions?page=${page}&size=${pageSize}${filterString ? '&filter=' + filterString : ''}` +
//     `&sort=createdAt,DESC`;
//   try {
//     const data = await AxiosInstance.get(url).then((res) => res.data);
//     const items: IWithdrawRequest[] = [];
//     data.items.forEach((row: any) => {
//       const item: IWithdrawRequest = {
//         id: row.submissionId,
//         status: row.status,
//         data: {
//           amount: row.amount,
//           content: row.content,
//           recipientAddress: row.recipient,
//           tokenType: row.tokenType,
//           tokenAddress: row.tokenAddress,
//           chainId: row.chainId,
//           chainName: row.chainName,
//         },
//         submissions: [],
//         createdAt: row.createdAt,
//         createdBy: row.createdBy,
//         updatedAt: row.updatedAt,
//         updatedBy: row.updatedBy,
//       };
//       items.push(item);
//     });
//     return { ...data, items };
//   } catch (e: any) {
//     console.log('[API] Can not get data', e);
//     let msg: string = e.message;
//     if (e.response && e.response.data && e.response.data.message) {
//       msg = e.response.data.message;
//     }
//     // return {
//     //   code: 5000,
//     //   message: msg,
//     //   details: e?.response?.data,
//     // };
//     throw e;
//   }
// };

// const getRawTransaction = async (chainId: number, txIndex: number, transactionType: string) => {
//   let url = `/withdrawal/${transactionType}/${chainId}/${txIndex}`;
//   return AxiosInstance.get(url).then(function (res) {
//     return res.data;
//   });
// };

// const updateTransaction = async (chainId: number, txIndex: number, txHash: string) => {
//   let url = `/withdrawal/${chainId}/${txIndex}?txHash=${txHash}`;
//   return AxiosInstance.put(url).then(function (res) {
//     return res.data;
//   });
// };

export interface FormUpdateCurrency {
  balanceMin: number;
  balanceMax: number;
  amountMin: number;
  amountMax: number;
  coldWallet: string;
}
export const updateCurrency = async (
  id: string,
  { balanceMin, balanceMax, amountMin, amountMax, coldWallet }: FormUpdateCurrency
) => {
  let url = `/assets/${id}/setting`;
  try {
    const formData = {
      minAccountBalance: balanceMin,
      maxAccountBalance: balanceMax,
      minTransfer: amountMin,
      maxTransfer: amountMax,
      coldWalletAddress: coldWallet,
      id,
    };
    const data = await AxiosInstance.put(url, formData).then((res) => res.data);

    // console.log("put data", data);

    return {
      status: true,
      msg: data.message,
    };
  } catch (e) {
    console.log('[API] Can not put data', e);
    return {
      status: false,
      msg: 'ERROR',
    };
  }
};
const CurrencyService = {
  getCurrencyBalance,
  getCurrencyList,
  getCurrencyHistory,
  updateCurrency,
  requestWithdraw,
  getAddRemoveLiquidityPool,
  // getWithdrawRequests,
  // getWithdrawRequestDetail,
  // getRawTransaction,
  // updateTransaction,
};
export default CurrencyService;
