import AxiosInstance from './axios';
import { IFilterTransaction, TransactionModel } from '../store/transaction/types';
import { toast } from 'react-toastify';

export const getTransaction = async (params: IFilterTransaction): Promise<TransactionModel> => {
  try {
    const res: TransactionModel = await AxiosInstance.get('/nft-dapp/nfts/request-history', {
      params,
    }).then((res) => res.data);
    const data = (res.items || []).map((i) => ({
      ...i,
      amount: i.amount || 0,
      feeAmount: i.feeAmount || 0,
      feePercent: i.feePercent || 0,
    }));

    const exportData = data.map((d) => ({
      'Mã thanh toán': d.paymentId,
      'Trạng thái': d.status,
      'Thời gian tạo': d.createdAt,
      'Thời gian hoàn thành': d.updatedAt,
      'Số tiền': d.amount,
      'Tiền Phí': d.feeAmount,
      'Tổng tiền': d.amount + d.feeAmount,
      'Loại giao dịch': d.txType,
      'Tên mạng': d.chainName,
      'Mã giao dịch trên chain': d.txHash,
    }));

    return {
      ...res,
      items: data,
      export: exportData,
    };
  } catch (err: any) {
    toast.error(err.response.data.message, {
      autoClose: 2000,
    });
    throw err;
  }
};
export const exportExcel = async (params: IFilterTransaction, token: string) => {
  try {
    delete params.page;
    const searchParams: any = params;
    const querystring = new URLSearchParams(searchParams);
    const url = `/nft-dapp/nfts/request-history/export`;
    const res = await AxiosInstance.get(`${url}?${querystring}`, { responseType: 'blob' });
    const disposition = res.headers['content-disposition']?.toString();
    const filenameRegex = /filename[^;=\n]*=(([‘“]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition || '');
    const filename =
      matches && matches[1] ? matches[1].replace(/['"]/g, '') : `download-file-${new Date().getTime()}.xlsx`;

    const dataUrl = URL.createObjectURL(res.data);
    // console.log('dataUrl', dataUrl);

    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log('error', error);
  }
};

const TransactionService = { getTransaction, exportExcel };
export default TransactionService;
