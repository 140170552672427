import { FC, useRef, useEffect, useState, Dispatch, SetStateAction, createContext, useContext } from 'react';
import { LayoutSplashScreen } from '../../layout/core';
import AuthService from '../../services/AuthService';
import { UserModel } from '../../types/user/UserModel';
import AuthHelpers from '../../helpers/AuthHelpers';
import { AuthModel } from '../../types/Auth';

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: AuthHelpers.getAuth(),
  saveAuth: () => null,
  currentUser: undefined,
  setCurrentUser: () => null,
  logout: () => null,
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(AuthHelpers.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const saveAuth = (auth?: AuthModel) => {
    setAuth(auth);
    if (auth) {
      AuthHelpers.setAuth(auth);
    } else {
      AuthHelpers.removeAuth();
    }
  };
  //
  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  };

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<{ children?: React.ReactNode }> = (props) => {
  const { auth, setCurrentUser, logout } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const data = await AuthService.getMe(apiToken);
          if (data) {
            setCurrentUser(data);
          }
        }
      } catch (error) {
        console.log('AuthInit', error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (auth && auth.token && !AuthHelpers.isTokenExpired(auth.token)) {
      requestUser(auth.token);
    } else {
      logout();
      setShowSplashScreen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
};

export default AuthInit;
