import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { defineds } from '../../components/date-range-picker/defaultRanges';
import { useSelector } from 'react-redux';
import { getTransactionSelector } from '../../store/transaction/selectors';
import moment from 'moment';
import { Transactions } from './Transactions';
import { Select } from '../../components/Select';
import { DateRangePicker } from '../../components/date-range-picker';
import { ExportCSV } from '../../components/common/export-xlsx';
import { FORMAT_DATE_FILTER } from '../../constants';
import { TxStatus, TxTypes } from '../../types/Transaction';
import { IFilterTransaction } from '../../store/transaction/types';
import TabItem from '../../components/TabItem';

type Props = {
  className?: string;
};

const PageTransactionHistory: React.FC<Props> = () => {
  const [filter, setFilter] = useState<IFilterTransaction>({
    status: '',
    txType: '',
    fromDate: moment(defineds.oneWeek).format(FORMAT_DATE_FILTER),
    toDate: moment(defineds.endOfToday).format(FORMAT_DATE_FILTER),
    page: 1,
    size: 5
  });
  const transaction = useSelector(getTransactionSelector);
  const handleChange = (params: IFilterTransaction) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };

  const filterType = Object.keys(TxTypes).map((key) => ({
    label: key,
    value: key === 'ALL' ? '' : key,
  }));

  const filterStatus = Object.keys(TxStatus).map((key) => ({
    label: key,
    value: key === 'ALL' ? '' : key,
  }));

  const handleChangeDate = (params: { startDate?: Date; endDate?: Date; key: string; active: string }) => {
    setFilter((prev) => ({
      ...prev,
      fromDate: params.active === 'all' ? undefined : moment(params.startDate).format(FORMAT_DATE_FILTER),
      toDate: params.active === 'all' ? undefined : moment(params.endDate).format(FORMAT_DATE_FILTER),
      page: 1,
    }));
  };

  return (
    <Transactions filter={filter} handleChange={handleChange} title="transactionHistory">
      <>
        <div className="col-auto">
          <label className="form-label">
            <FormattedMessage id="transactionStatus" />
          </label>
          <Select
            options={filterStatus}
            name={filter.status || ''}
            onChange={(value) => handleChange({ status: value, page: 1 })}
            style={{ height: 42 }}
          />
        </div>

        <div className="col">
          <DateRangePicker
            style={{ background: '#DFE1E6' }}
            onChangeDate={handleChangeDate}
            extraActions={
              <ExportCSV filter={filter} csvData={transaction.export}>
                <TabItem label={<i className="fas fa-download text-dark" />} onClick={() => { }} />
              </ExportCSV>
            }
          />
        </div>
      </>
    </Transactions >
  );
};

export default PageTransactionHistory;
