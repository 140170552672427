import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { toAbsoluteUrl } from '../helpers';

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white');
    return () => {
      document.body.classList.remove('bg-white');
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        background: `url(${toAbsoluteUrl('/img/bg.png')})`,
        backgroundSize: "cover",
        backgroundPosition: "center center"
      }}
    >

      {/* begin::Content */}
      < div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20" >
        {/* begin::Logo */}

        < a className="btn btn-transition d-flex px-0 mb-10" href="/dashboard" >
          <img alt="Logo" src="/img/Layer_1.png" className="app-sidebar-logo-default" style={{ height: 120 }} />
        </a >

        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div >
      {/* end::Content */}
    </div >
  );
};
export default AuthLayout;
