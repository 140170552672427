import { lazy, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { PageDataProvider } from "./core";
import { useLocation } from "react-router-dom";
import { MenuComponent } from "../assets/ts/components";
import { SuspensedView } from "../routing/PrivateRoutes";
import { Sidebar } from "./components/Sidebar";
import { FooterWrapper } from "./components/footer/FooterWrapper";
import { ToolbarWrapper } from "./components/toolbar";
import { useAuth } from "../store/auth";

const DataInit = lazy(() => import("../store/DataInit"));

const MasterLayout = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 1500);
  }, [location.key]);

  return (
    <PageDataProvider>
      <SuspensedView>
        <DataInit>
          <div
            className="d-flex flex-column flex-root app-root"
            id="kt_app_root"
          >
            <div
              className="app-page flex-column flex-column-fluid"
              id="kt_app_page"
            >
              <HeaderWrapper />
              <div
                className="app-wrapper flex-column flex-row-fluid"
                id="kt_app_wrapper"
              >
                {/* <Sidebar /> */}
                <div
                  className="app-main flex-column flex-row-fluid"
                  id="kt_app_main"
                >
                  <div className="d-flex flex-column flex-column-fluid">
                    <ToolbarWrapper />
                    <Content>
                      <Outlet />
                    </Content>
                  </div>
                  {/* <FooterWrapper /> */}
                </div>
              </div>
            </div>
          </div>
        </DataInit>
        <ScrollTop />
      </SuspensedView>
    </PageDataProvider>
  );
};

export { MasterLayout };
