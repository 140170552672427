import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_TRANSACTION_REQUEST, TRANSACTION } from './actions';
import TransactionService from '../../services/TransactionService';
import { FetchTransaction, TransactionModel } from './types';

function* fetchTransaction({ payload }: FetchTransaction) {
  try {
    const response: TransactionModel = yield call(TransactionService.getTransaction, payload);
    yield put(TRANSACTION.SUCCESS(response));
  } catch (err: any) {
    yield put(TRANSACTION.ERROR({ error: err.message }));
    console.log(`Func: fetchTransactionSaga - PARAMS: err`, err);
  }
}

function* settingsSaga() {
  yield takeEvery(GET_TRANSACTION_REQUEST, fetchTransaction);
}

export default settingsSaga;
