import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { ellipseAddress } from "../../helpers";
import { FaIcons } from "../../helpers/FaIconHelper";

const Address = ({
  className = "d-flex",
  linkClassName = "",
  showCopy = true,
  address,
  link,
}: {
  linkClassName?: string;
  className?: string;
  address: string;
  showCopy?: boolean;
  link?: string;
}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
    }
  }, [copySuccess]);
  if (!address) {
    return <></>;
  }
  return (
    <span className={className}>
      <OverlayTrigger
        key="address"
        placement="top"
        overlay={<Tooltip id="tooltip-address">{address}</Tooltip>}
      >
        {link ? (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className={linkClassName}
          >
            <span>{ellipseAddress(address, 4)}</span>
          </a>
        ) : (
          <span>{ellipseAddress(address, 4)}</span>
        )}
      </OverlayTrigger>
      {showCopy === true && address !== "" && (
        <OverlayTrigger
          key="copy-to-clipboard"
          placement="top"
          overlay={<Tooltip id="tooltip-copy-to-clipboard">Sao chép</Tooltip>}
        >
          <CopyToClipboard
            text={address}
            onCopy={() => {
              setCopySuccess(true);
            }}
          >
            <i
              className={`cursor-pointer fs-4 px-2 ${linkClassName}  ${
                copySuccess
                  ? `${FaIcons["fas fa-check"]} text-success`
                  : FaIcons["far fa-copy"]
              }`}
            ></i>
          </CopyToClipboard>
        </OverlayTrigger>
      )}
    </span>
  );
};
export { Address };
