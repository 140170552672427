import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { DateRangePicker as DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { defaultStaticRanges, defineds, selectDateRange, SelectRange } from './defaultRanges';
import clsx from 'clsx';
import { endOfDay } from 'date-fns';

interface IDateRangePicker {
  style?: CSSProperties;
  className?: string;
  onChangeDate: (params: IDate) => void;
  extraActions?: ReactNode;
  showOneMonth?: boolean;
  showOneYear?: boolean;
  showAll?: boolean;
}

interface IDate {
  startDate?: Date;
  endDate?: Date;
  key: string;
  active: string;
}

export const DateRangePicker = ({
  style,
  className,
  onChangeDate,
  extraActions,
  showOneMonth = true,
  showOneYear = true,
  showAll = true,
}: IDateRangePicker) => {
  const ref = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<IDate[]>([selectDateRange('oneWeek')]);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target) && open) {
        handleClose();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [state]);

  const handleOnChange = ({ selection }: any) => {
    const params = {
      ...selection,
      endDate: endOfDay(selection.endDate),
      active: 'custom',
    };
    handleClose();
    setState([params]);
    onChangeDate?.(params);
  };

  const handleChangeDate = (key: SelectRange) => {
    handleClose();
    setState([selectDateRange(key)]);
    onChangeDate?.(selectDateRange(key));
  };

  return (
    <div className={clsx(className, 'position-relative p-1 rounded d-inline-flex')} style={style}>
      <div className="d-flex align-items-center gap-1">
        <TabItem active={state[0].active === 'oneDate'} onClick={() => handleChangeDate('oneDate')} label="1D" />
        <TabItem active={state[0].active === 'oneWeek'} onClick={() => handleChangeDate('oneWeek')} label="1W" />
        {showOneMonth && (
          <TabItem active={state[0].active === 'oneMonth'} onClick={() => handleChangeDate('oneMonth')} label="1M" />
        )}
        {showOneYear && (
          <TabItem active={state[0].active === 'oneYear'} onClick={() => handleChangeDate('oneYear')} label="1Y" />
        )}
        {showAll && (
          <TabItem active={state[0].active === 'all'} onClick={() => handleChangeDate('all')} label="TẤT CẢ" />
        )}
        <TabItem
          active={state[0].active === 'custom'}
          onClick={() => setOpen(!open)}
          label={<i className="far fa-calendar-alt text-dark" />}
        />
        {extraActions && (
          <>
            <span style={{ color: '#D6DBE7' }}>|</span>
            {extraActions}
          </>
        )}
      </div>

      {open && (
        <div
          className="position-absolute end-0 top-100 border shadow rounded-3 overflow-hidden"
          style={{ zIndex: 1020 }}
          ref={ref}
        >
          <DateRange
            onChange={handleOnChange}
            months={2}
            ranges={state}
            direction="horizontal"
            staticRanges={defaultStaticRanges}
            inputRanges={[]}
            maxDate={defineds.endOfToday}
          />
        </div>
      )}
    </div>
  );
};

export const TabItem = ({
  label,
  className,
  onClick,
  active,
  activeClass = 'bg-white',
}: {
  label: ReactNode;
  onClick: () => void;
  active?: boolean;
  className?: string;
  activeClass?: string;
}) => {
  return (
    <div
      className={clsx('user-select-none px-5 py-2 rounded fw-semibold cursor-pointer fs-7 border', className, {
        [activeClass]: active,
      })}
      onClick={onClick}
    >
      {label}
    </div>
  );
};
