import { BigNumber, ethers, utils } from 'ethers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers';
import { connectWeb3 } from './SwapServices';
import { PAIR_ABI } from './abi/ROUTER_ABI';
import NFT_ABI from './abi/NFT_ABI.json';

export function getProvider(rpcUrl: any) {
  const localProvider = createProvider(rpcUrl);
  return localProvider;
}
export function createProvider(url: string | ethers.utils.ConnectionInfo | undefined) {
  try {
    return new ethers.providers.JsonRpcProvider(url);
  } catch (e) {
    console.log(e);
  }
}
// 0xce4e9C83d2DE2522E2E16e50934ea232F051F65b
// account is optional
export function getContract(address: string, ABI: any, library: JsonRpcProvider, signer?: any) {
  return new ethers.Contract(address, ABI, signer);
}
// account is not optional
export function getSigner(library: JsonRpcProvider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

export const sendTransaction = async (params: any, signer: any, address: any): Promise<TransactionResponse> => {
  console.log(params, signer, address);
  try {
    let tx: any = {
      chainId: params['chainId'],
      nonce: params['nonce'],
      to: params['to'],
      value: utils.parseEther('0'),
      data: params['data'],
      gasLimit: params['gasLimit'] ?? 600000,
    };
    if (params['maxPriorityFeePerGas']) {
      tx = {
        ...tx,
        maxPriorityFeePerGas: BigNumber.from('' + params['maxPriorityFeePerGas']),
        maxFeePerGas: BigNumber.from('' + params['maxFeePerGas']),
      };
    }
    return signer.sendTransaction(tx);
  } catch (e) {
    //console.log(e)
    throw e;
  }
};

// export const getTokenBalance = async ({ tokenAddress, account }: { tokenAddress: string; account: string }) => {
//   const { signer, provider } = await connectWeb3();

//   const myContract = getContract(tokenAddress, PAIR_ABI, provider, signer);
//   // console.log('myContract', myContract);
//   const etherBalance = await myContract.balanceOf(account);

//   return ethers.utils.formatEther(etherBalance);
// };

export const noncesOf = async ({ contractAddress, tokenId }: { contractAddress: string; tokenId: any }) => {
  const { signer, provider } = await connectWeb3();
  if (provider) {
    const myContract = getContract(contractAddress, NFT_ABI, provider, signer);
    console.log(myContract, tokenId);
    const nonce = await myContract.noncesOf(tokenId);
    return nonce.toString().split(',');
  }
};

export const getNetworkName = async () => {
  const { signer, provider } = await connectWeb3();
  if (provider) {
    const networkData = await provider.getNetwork();
    return networkData;
  }
};
export const createRawTransaction = async ({
  chainId,
  decimals = 18,
  contractAddress,
  to,
  value = 0,
  args,
  contractFunction,
  abi,
  gasLimit = 2000000,
  maxFeePerGas = 20000000000,
  nonce = 0,
  signer,
  provider,
}: {
  chainId: number;
  decimals?: number;
  contractAddress: string;
  to: string;
  value?: number;
  args: any[];
  contractFunction: string;
  abi: any;
  gasLimit?: number;
  maxFeePerGas?: number;
  nonce?: number;
  signer: any;
  provider: any;
}) => {
  const myContract = getContract(contractAddress, abi, provider, signer);
  // console.log('myContract', myContract)
  const encodeData = myContract.interface.encodeFunctionData(contractFunction, args);

  console.log('encodeData', encodeData);

  return {
    chainId,
    nonce,
    to,
    value: ethers.utils.parseUnits(String(value), decimals),
    data: encodeData,
    gasLimit,
    maxFeePerGas,
  };
};

export const signTransaction = async (dataNone: string, singer: any) => {
  try {
    var signature = await singer.signMessage(dataNone);
    return { signature };
  } catch (e) {
    throw e;
  }
};

export async function connectMetaMask(chainId?: any): Promise<{ address: string }> {
  const { ethereum } = window;
  try {
    let address = '';
    let installed: boolean = true;
    let connected: boolean = false;
    let error: any = null;
    let signer: any;
    await ethereum.enable();
    const { provider } = await connectWeb3();
    //  const provider = new ethers.providers.Web3Provider(ethereum, 'any');
    try {
      if (provider) {
        await provider.send('eth_requestAccounts', []);
        // TODO what is this?
        provider.on('accountsChanged', function (accounts: any[]) {
          address = accounts[0];
        });
        if (chainId) {
          await switchChain(chainId);
        }
        signer = provider.getSigner();
        //  this.signer = signer;

        address = await signer.getAddress();
      }
    } catch (e) {
      // console.log('ethereum error', e);
      error = e;
      connected = false;
    }

    return { address };
  } catch (e) {
    //console.log(e)
    throw e;
  }
}

export const signNFTData = async ({
  chainId,
  tokenId,
  signer,
  nftContract,
  paymentContract,
  nonce,
  details,
  deadline,
}: {
  chainId: any;
  tokenId: number;
  nonce: number;
  signer: any;
  nftContract: string;
  paymentContract: string;
  details?: any;
  deadline: number;
}) => {
  console.log('signer', signer);
  try {
    const PermitDetails = [
      {
        name: 'tokenId',
        type: 'uint256',
      },
      {
        name: 'nonce',
        type: 'uint48',
      },
    ];
    const typedData = {
      types: {
        PermitDetails: PermitDetails,
        PermitBatch: [
          { name: 'details', type: 'PermitDetails[]' },
          { name: 'spender', type: 'address' },
          { name: 'sigDeadline', type: 'uint256' },
        ],
      },
      primaryType: 'PermitBatch',
      domain: {
        name: 'VieNFT',
        version: '1',
        chainId: chainId,
        verifyingContract: nftContract, // nft address nftContract
      },
      message: {
        details: details,
        spender: paymentContract,
        sigDeadline: deadline,
      },
    };

    // const typedData = {
    //   types: {
    //     Permit: [
    //       { name: 'spender', type: 'address' },
    //       { name: 'tokenId', type: 'uint256' },
    //       { name: 'nonce', type: 'uint256' },
    //       { name: 'deadline', type: 'uint256' },
    //     ],
    //   },
    //   primaryType: 'Permit',
    //   domain: {
    //     name: 'VieNFT',
    //     version: '1',
    //     chainId: chainId,
    //     verifyingContract: nftContract, // nft address
    //   },
    //   message: {
    //     spender: paymentContract,
    //     tokenId,
    //     nonce, // contract.nonceOf(tokenId)+1
    //     deadline,
    //   },
    // };

    console.log('signer', signer);
    return signer._signTypedData(typedData.domain, typedData.types, typedData.message);
  } catch (e: any) {
    console.log(e);
    return e;
  }
};

export const switchChain = async (chainId: number) => {
  const { signer, provider } = await connectWeb3();
  const { ethereum } = window;
  if (chainId) {
    const res = await ethereum.send('wallet_switchEthereumChain', [{ chainId: `0x${chainId.toString(16)}` }]);

    console.log('res', provider);

    return true;
  }
  return false;
};
