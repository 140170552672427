import clsx from 'clsx';
import { useIntl } from 'react-intl';

const LabelStatus = ({
  status,
  statusClasses,
  className = '',
  text,
}: {
  status: string;
  statusClasses?: any;
  className?: string;
  text?: string;
}) => {
  const intl = useIntl();
  const { $t } = intl;

  const statusClass = statusClasses ? statusClasses[status] : 'badge badge-light-info text-muted text-uppercase';

  return (
    <span className={clsx(statusClass, className)}>
      {status && $t({ id: status })} {text}
    </span>
  );
};

export default LabelStatus;
