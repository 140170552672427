/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from 'react-router-dom';
import { ForgotPassword } from '../components/auth/ForgotPassword';
import { PageLogin } from '../components/auth/PageLogin';
import { Registration } from '../components/auth/Registration';

import AuthLayout from '../layout/AuthLayout';

const AuthRoutes = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<PageLogin />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route index element={<PageLogin />} />
    </Route>
  </Routes>
);

export { AuthRoutes };
