import AxiosInstance from './axios';
import { SettingsModel } from '../store/settings/types';
import { toast } from 'react-toastify';

export const getSettings = async (): Promise<SettingsModel> => {
  try {
    const { data }: { data: SettingsModel } = await AxiosInstance.get('/merchant/settings');
    return {
      ...data,
      selectedChain: data.paymentGateways?.[0]?.chainName,
      address: data.paymentGateways?.[0]?.address,
    };
  } catch (err: any) {
    toast.error(err?.response?.data?.message, {
      autoClose: 2000,
    });
    throw err;
  }
};

export const deleteTransaction = async () => {
  try {
    const res = await AxiosInstance.post('/merchant/suspend');
    if (res.data) toast.success('Chặn giao dịch thành công!', { autoClose: 2000 });
    return res.data;
  } catch (err: any) {
    toast.error(err?.response?.data?.message, {
      autoClose: 2000,
    });
    throw err;
  }
};
