import { useIntl, FormattedMessage } from 'react-intl';
// import LoginByGoogleBtn from './LoginByGoogleBtn';
import LoginByWalletBtn from './LoginByWalletBtn';

export function PageLogin() {

  const intl = useIntl();

  return (
    <section className=''>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">{intl.formatMessage({ id: 'AUTH.SignInToAdmin' })}</h1>
        <div className="text-gray-400 fw-bold fs-4"></div>
      </div>

      <div className="text-center">
        {/* <LoginByGoogleBtn /> */}
        <LoginByWalletBtn />
      </div>
    </section>
  );
}