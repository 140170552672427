import {
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  UPDATE_CHAIN_SELECTED,
  UPDATE_SUSPEND,
} from './actions';
import { SettingsActions, SettingsState } from './types';

const initialState: SettingsState = {
  pending: false,
  error: null,
  data: {
    merchant: {
      id: '',
      name: '',
      email: '',
      apiKey: '',
      depositAddress: '',
      withdrawAddress: '',
      suspended: false,
    },
    paymentGateways: [],
    selectedChain: '',
    address: '',
  },
};

const reducer = (state = initialState, action: SettingsActions) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
      };
    case GET_SETTINGS_FAILED:
      return {
        ...state,
        ...initialState,
        ...action.payload,
      };
    case UPDATE_CHAIN_SELECTED:
      return {
        ...state,
        ...initialState,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case UPDATE_SUSPEND:
      return {
        ...state,
        ...initialState,
        data: {
          ...state.data,
          merchant: {
            ...state.data.merchant,
            ...action.payload,
          },
        },
      };
    default:
      return { ...state };
  }
};
export default reducer;
