import { SettingsModel } from './types';

export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const UPDATE_CHAIN_SELECTED = 'UPDATE_CHAIN_SELECTED';
export const UPDATE_SUSPEND = 'UPDATE_SUSPEND';

export const SETTINGS = {
  REQUEST: () => {
    return {
      type: GET_SETTINGS_REQUEST,
    };
  },
  SUCCESS: (payload: SettingsModel) => {
    return {
      type: GET_SETTINGS_SUCCESS,
      payload,
    };
  },
  ERROR: (payload: any) => {
    return {
      type: GET_SETTINGS_FAILED,
      payload: {
        ...(payload?.response?.data || {}),
      },
    };
  },
  DELETE_TRANSACTION: () => {
    return {
      type: DELETE_TRANSACTION,
    };
  },
  UPDATE_CHAIN_SELECTED: (payload: { selectedChain: string }) => {
    return {
      type: UPDATE_CHAIN_SELECTED,
      payload,
    };
  },
  UPDATE_SUSPEND: (payload: { suspended: boolean }) => {
    return {
      type: UPDATE_SUSPEND,
      payload,
    };
  },
};
