import { ChangeEventHandler, FC } from 'react';
import { WithChildren } from '../../helpers/react18MigrationHelpers';

interface Props {
  checked?: boolean;
  color?: string;
  value?: string | number;
  id: string;
  onChange?: ChangeEventHandler | undefined;
}

const Checkbox: FC<Props & WithChildren> = ({
  checked = false,
  color = 'primary',
  value = 1,
  onChange,
  children,
  id,
}) => {
  return (
    <div className={`form-check form-check-custom form-check-${color} form-check-sm `}>
      <input
        className="form-check-input"
        type="checkbox"
        checked={checked}
        // defaultChecked={checked}
        value={value}
        id={`flexCheckDefault-${id}`}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={`flexCheckDefault-${id}`}>
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
