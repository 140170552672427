import { IFilterTransaction, TransactionModel } from './types';

export const GET_TRANSACTION_REQUEST = 'GET_TRANSACTION_REQUEST';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_FAILED = 'GET_TRANSACTION_FAILED';

export const TRANSACTION = {
  REQUEST: (payload: IFilterTransaction) => {
    return {
      type: GET_TRANSACTION_REQUEST,
      payload,
    };
  },
  SUCCESS: (payload: TransactionModel) => {
    return {
      type: GET_TRANSACTION_SUCCESS,
      payload,
    };
  },
  ERROR: (payload: any) => {
    return {
      type: GET_TRANSACTION_FAILED,
      payload: {
        ...payload,
      },
    };
  },
};
