import { Fragment, useEffect, useState } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

const Pagination = ({
  visiblePageCount = 5,
  limit = 10,
  page = 1,
  total = 5,
  FirstButton,
  LastButton,
  NextButton = (props: any) => (<li {...props}><AiOutlineRight /></li>),
  PrevButton = (props: any) => (<li {...props}><AiOutlineLeft /></li>),
  PageButton = (props: any) => <li {...props}>{props.children}</li>,
  DotsButton,
  hasDots,
  onPageChange,
}: {
  limit: number;
  page: number;
  total: number;
  FirstButton?: any;
  LastButton?: any;
  PrevButton?: any;
  NextButton?: any;
  DotsButton?: any;
  PageButton?: any;
  visiblePageCount?: number;
  hasDots?: boolean;
  onPageChange: any;
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [pageCount, setPageCount] = useState(0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [pages, setPages] = useState([{ index: 1 }]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const pageCount = Math.floor(total / limit) + (total % limit > 0 ? 1 : 0);
    setPageCount(pageCount);
    if (page < 1 || !pageCount) onPageChange(1);
    else if (page > pageCount) onPageChange(pageCount);
  }, [total, limit]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (pageCount && page >= 1 && page <= pageCount) {
      const prevPageCount = Math.floor(visiblePageCount / 2);
      let minPage = page - prevPageCount;
      if (minPage <= 0) minPage = 1;

      const nextPageCount = visiblePageCount - prevPageCount;
      let maxPage = page + nextPageCount - 1;
      if (maxPage > pageCount) maxPage = pageCount;

      while (minPage + visiblePageCount > pageCount + 1 && minPage > 1) minPage--;
      const visiblePages = Array.from(Array(visiblePageCount > pageCount ? pageCount : visiblePageCount).keys()).map(
        (x) => ({ index: x + minPage })
      ) as { index: any }[];

      if (hasDots && pageCount > visiblePages.length) {
        visiblePages.splice(0, 1, { index: 1 });
        visiblePages.splice(visiblePages.length - 1, 1, { index: pageCount });
        if (minPage >= prevPageCount) {
          visiblePages.splice(1, 0, { index: -2 });
        }
        if (maxPage <= pageCount - nextPageCount + 2) {
          visiblePages.splice(visiblePages.length - 1, 0, { index: -1 });
        }

        if (minPage < prevPageCount) {
          for (let i = 0; i < visiblePageCount; i++) {
            if (visiblePages[i].index == i + 1) continue;
            else {
              if (visiblePages[i].index < 0) {
                visiblePages.splice(i, 0, { index: i + 1 });
              } else {
                visiblePages.splice(i, 1, { index: i + 1 });
              }
            }
          }
        }
        if (maxPage > pageCount - nextPageCount + 2) {
          const lastIndex = visiblePages.length - 1;
          for (let i = 0; i < visiblePageCount; i++) {
            if (visiblePages[lastIndex - i].index == pageCount - i) continue;
            else {
              if (visiblePages[lastIndex - i].index < 0) {
                visiblePages.splice(lastIndex - i + 1, 0, { index: pageCount - i });
              } else {
                visiblePages.splice(lastIndex - i, 1, { index: pageCount - i });
              }
            }
          }
        }

        if (visiblePages[0].index == 1 && visiblePages[1].index < 0 && visiblePages[2].index == 3) {
          visiblePages[1].index = 2;
        }
        if (visiblePages[0].index == 1 && visiblePages[1].index < 0 && visiblePages[2].index == 2) {
          visiblePages.splice(1, 1);
        }
        if (
          visiblePages[visiblePages.length - 1].index == pageCount &&
          visiblePages[visiblePages.length - 2].index < 0 &&
          visiblePages[visiblePages.length - 3].index == pageCount - 2
        ) {
          visiblePages[visiblePages.length - 2].index = pageCount - 1;
        }
        if (
          visiblePages[visiblePages.length - 1].index == pageCount &&
          visiblePages[visiblePages.length - 2].index < 0 &&
          visiblePages[visiblePages.length - 3].index == pageCount - 1
        ) {
          visiblePages.splice(visiblePages.length - 2, 1);
        }
      }

      // visiblePages.pop();
      setPages(visiblePages);
    }
  }, [visiblePageCount, pageCount, page]);

  const handlePageChange = (pageIndex: number) => {
    if (pageIndex < 1 || pageIndex > pageCount) return;
    if (pageIndex != page) onPageChange(pageIndex);
  };

  return (
    <div className='d-flex justify-content-center pb-10'>
      <ul className="pagination border rounded">
        {FirstButton && (
          <FirstButton className="d-flex align-items-center justify-content-center min-h-40px fw-semibold min-w-40px cursor-pointer user-select-none border-start" onClick={() => handlePageChange(1)} disabled={page <= 1} />
        )}
        {PrevButton && (
          <PrevButton
            className="d-flex align-items-center justify-content-center min-h-40px fw-semibold min-w-40px cursor-pointer user-select-none border-start"
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
          />
        )}
        {pages.map((currentPage, k) => (
          <Fragment key={k}>
            {currentPage.index > 0 && PageButton && (
              <PageButton
                className={`d-flex align-items-center justify-content-center min-h-40px fw-semibold min-w-40px cursor-pointer user-select-none border-start ${currentPage.index === page ? 'bg-primary text-white' : ''}`}
                key={currentPage.index}
                disabled={currentPage.index < 0 || typeof currentPage.index == 'string'}
                onClick={() => handlePageChange(currentPage.index)}
              >
                {currentPage.index}
              </PageButton>
            )}
            {currentPage.index <= 0 && DotsButton && (
              <DotsButton
                key={currentPage.index}
                disabled={currentPage.index < 0 || typeof currentPage.index == 'string'}
                onClick={() => handlePageChange(currentPage.index)}
              />
            )}
          </Fragment>
        ))}
        {NextButton && (
          <NextButton
            className="d-flex align-items-center justify-content-center min-h-40px fw-semibold min-w-40px cursor-pointer user-select-none border-start"
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= pageCount}
          />
        )}
        {LastButton && (
          <LastButton
            className="d-flex align-items-center justify-content-center min-h-40px fw-semibold min-w-40px cursor-pointer user-select-none border-start"
            onClick={() => handlePageChange(pageCount)}
            disabled={page >= pageCount}
          />
        )}
      </ul>
    </div>
  );
};

export default Pagination;
