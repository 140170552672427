import AxiosInstance from './axios';

/**
 * Log user in
 *
 * @param email Email
 * @param password Password
 *
 * @returns object
 */
const login = async (email: string, password: string): Promise<{ status: boolean; msg: string; data?: any }> => {
  let status = false;
  let msg = '';
  return AxiosInstance.post('/auth/sign-in', {
    email,
    password,
  })
    .then((res) => {
      msg = 'LoginSuccess';
      status = true;

      return { status, msg, data: res.data };
    })
    .catch((error) => {
      msg = 'WrongEmailOrPassword';
      return {
        status,
        msg: error?.message ? error.message : 'Error',
        data: error,
      };
    });
};

/**
 * Log google in
 *
 * @param token Access token google
 *
 * @returns object
 */
const googleSignin = async (token?: string): Promise<{ status: boolean; msg: string; data?: any }> => {
  let status = false;
  let msg = '';
  return AxiosInstance.post('/auth/google-sign-in', {
    token,
  })
    .then((res) => {
      msg = 'LoginSuccess';
      status = true;

      return { status, msg, data: res.data };
    })
    .catch((error) => {
      msg = 'WrongEmailOrPassword';
      return {
        status,
        msg: error?.message ? error.message : 'Error',
        data: error,
      };
    });
};

const register = async (email: string, password: string, name: string) => {
  var params = {
    email: email,
    name: name,
    password: password,
  };
  return await AxiosInstance.post('/auth/sign-up', params)
    .then((res) => ({ status: true, data: res.data, msg: 'Success' }))
    .catch((err) => {
      console.log('[API] Register error', err);
      return { status: false, data: err, msg: err?.message ? err.message : 'Error' };
    });
};

const forgotPassword = async (email: string) => {
  return AxiosInstance.post<{ result: boolean }>('/forgot_password', {
    email,
  });
};

const getMe = async (token: string) => {
  try {
    let res = await AxiosInstance.get('/distributor/auth/user', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const userData = res.data;
    localStorage.setItem('user', JSON.stringify(userData));

    return userData;
  } catch (e) {
    console.log('[API] Can not get current user', e);
    throw e;
    // return null;
  }
};

const getWalletNone = (address: string) => {
  localStorage.setItem('address', address);
  return AxiosInstance.get('/distributor/auth/wallet/' + address + '/nonce');
};

const signWallet = (publicAddress: string, signature: string, nonce: string) =>
  AxiosInstance.post(`/distributor/auth/wallet/${publicAddress}/signature`, {
    nonce,
    signature,
  });

const AuthService = { login, register, forgotPassword, getMe, getWalletNone, signWallet, googleSignin };
export default AuthService;
