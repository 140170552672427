import { toAbsoluteUrl } from './helpers';
import config from './config.json';
const appConfig = {
  title: '',
  languages: [
    {
      lang: 'en',
      name: 'English',
      flag: toAbsoluteUrl('/img/flags/united-states.svg'),
    },
    {
      lang: 'vi',
      name: 'Tiếng Việt',
      flag: toAbsoluteUrl('/img/flags/vietnam.svg'),
    },
  ],
  api: config.api,
  links: config.links,
  googleClientId: config.googleClientId,
};

export default appConfig;
