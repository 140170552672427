import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import * as auth from '../../store/auth/AuthRedux';
import AuthService from '../../services/AuthService';
import { YupI18n } from '../../helpers/yup';
import { Alert, toAbsoluteUrl } from '../../helpers';
import { useAuth } from '../../store/auth';

import { ethers } from 'ethers';
import { signTransaction } from '../../services/ContractService';
import { getChainsSelector } from '../../store/chain/selectors';
import { Chains } from '../../types/Chain';
import { checkMetaMask } from '../../services/SwapServices';

let web3: Web3 | undefined = undefined;

const LoginByWalletBtn = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { saveAuth, setCurrentUser } = useAuth();
  const chains = useSelector(getChainsSelector);

  YupI18n();

  const intl = useIntl();


  const handleLoginMetamask = async () => {
    setLoading(true);

    let publicAddress = "";
    let signer: any
    const providerMaterMask = await detectEthereumProvider();
    const installedMetaMask = window?.ethereum && window?.ethereum?.isMetaMask;
    if (!providerMaterMask || !installedMetaMask) {
      Alert.error1(
        `<div className="text-muted">
       Vui lòng cài đặt tiện ích mở rộng trình duyệt Metamask để kết nối ví Metamask.
     </div>
     <br />
    <a class='btn btn-primary text-white' href='https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-started-with-MetaMask' target="_blank">Cài đặt</a>
   `,
        true,
        false
      );
      setLoading(false);
      return;

    } else {

      const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
      await window.ethereum?.enable();
      signer = provider.getSigner();

      if (!signer) {
        window.alert('Vui lòng bật tiện ích mở rộng metamask.');
        setLoading(false);
        return;
      }
      //  Look if user with current publicAddress is already present on backend
      publicAddress = await signer.getAddress();


    }
    if (publicAddress && signer) {
      AuthService.getWalletNone(publicAddress.toLocaleLowerCase())
        .then((res) => {
          const nonceData = res.data;
          const { nonce } = nonceData;
          // console.log("publicAddress", publicAddress, signer)
          signTransaction(`${nonce}`, signer)
            .then((signature) => {
              AuthService.signWallet(publicAddress.toLocaleLowerCase(), signature.signature, nonce)
                .then(async (res) => {
                  // console.log('res', res);
                  const { data } = res;
                  setLoading(false);
                  saveAuth(data);
                  AuthService.getMe(data.token)
                    .then((user) => {

                      setCurrentUser(user);
                      dispatch(auth.actions.login(data.token));
                    })
                    .catch((err) => {
                      console.log('err', err);
                      Alert.error(`Rất tiếc, địa chỉ của bạn không phụ thuộc danh sách hỗ trợ dịch vụ này`, false, false, "Kết nối bị từ chối");
                    });
                })
                .catch((err) => {
                  Alert.error(`Rất tiếc, địa chỉ của bạn không phụ thuộc danh sách hỗ trợ dịch vụ này`, false, false, "Kết nối bị từ chối");

                  setLoading(false);
                });
            })
            .catch((err: any) => {
              Alert.error('Đăng nhập bị từ chối trên Metamask của bạn. Hãy kiểm tra lại!', false, false, "Kết nối bị từ chối");
              setLoading(false);
            });


        })
        .catch((err) => {
          Alert.error('Đăng nhập bị từ chối trên Metamask của bạn. Hãy kiểm tra lại !');
          setLoading(false);
        });
    }

  };

  return (
    <>
      <button
        id="kt_sign_in_submit"
        className="btn btn-lg btn-primary w-100 mb-5"
        onClick={handleLoginMetamask}
      >
        {!loading && (
          <span className="indicator-label">{intl.formatMessage({ id: 'LoginWithX' }, { x: 'Metamask' })}</span>
        )}
        {loading && (
          <span className="indicator-progress" style={{ display: 'block' }}>
            <FormattedMessage id="PleaseWait" />
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </button>
      <div className="alert alert-warning text-start">
        <ul>
          <li>
            Hãy đảm bảo bạn đã cài đặt ví{' '}
            <a href="https://metamask.io/download/" target="_blank" rel="noreferrer">
              <img height="20px" src={toAbsoluteUrl('/img/svg/metamask.svg')} alt="metamask" />
            </a>{' '}
            trên trình duyệt của bạn.
          </li>
          <li>Nếu bạn có nhiều ví Ethereum, hãy tắt các ví khác trước khi đăng nhập. </li>
        </ul>
      </div>
    </>
  )
}

export default LoginByWalletBtn;

