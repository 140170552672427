import { FC } from 'react';

const Error404: FC = () => {
  return (
    <div className="card">
      <div className="card-body p-5">
        <h1 className="fw-bolder fs-4x text-gray-700 mb-5">Không tìm thấy trang</h1>

        <div className="fw-bold fs-3 text-gray-400 mb-15">
          Trang bạn tìm kiếm không tồn tại <br /> Hoặc dữ liệu đã bị xóa.
        </div>
      </div>
    </div>
  );
};

export { Error404 };
