import { ApiListModel } from '../types/Api';
import { IChain, INetwork, INetworks } from '../types/Chain';
import AxiosInstance from './axios';

const getChainList = async (): Promise<ApiListModel> => {
  const items: IChain[] = [];
  try {
    const networks: INetworks = await AxiosInstance.get('/chains').then((res) => res.data);

    Object.keys(networks).forEach((key: string, i) => {
      const data = (networks as any)[key] as INetwork;

      items.push({
        id: data.chainId,
        name: key,
        explorer: data.provider.explorer,
        logoUrl: data.provider.coinSymbol,
        nativeCurrency: {
          symbol: data.provider.coinSymbol,
          name: data.provider.coinSymbol,
          decimals: data.provider.coinDecimals,
        },
        contracts: data.contracts,
        tokens: data.tokens,
        gasPrice: data.gasPrice,
      });
    });

    return { page: 1, totalPages: 1, perPage: 20, totalItems: 20, items: items as IChain[] };
  } catch (e) {
    console.log('e', e);
    throw e;
  }
};

const ChainService = { getChainList };
export default ChainService;
