/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { useLayout } from "../../core";
import { Header } from "./Header";
import { Navbar } from "./Navbar";

export function HeaderWrapper() {
  const { config, classes } = useLayout();
  if (!config.app?.header?.display) {
    return null;
  }

  console.log("config.app.sidebar?.display", config.app.sidebar);

  return (
    <div id="kt_app_header" className="app-header relative bg-white">
      <div
        id="kt_app_header_container"
        className={clsx(
          "app-container flex-lg-grow-1",
          // classes.headerContainer.join(" "),
          config.app?.header?.default?.containerClass
        )}
        style={{ width: "100%" }}

      >

        {/* {config.app.sidebar?.display && ( */}
        <>
          <div
            className="d-flex align-items-center ms-n2 me-2"
            title="Show sidebar menu"
          >

            <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
              <Link to="/nft" className="d-lg">
                <img alt="Logo" src="/img/logo.png" className="h-35px" />
              </Link>
            </div>
            <div className="ant-divider ant-divider-vertical mx-3" role="separator"></div>
          </div>
        </>
        {/* )} */}

        {!config.app.sidebar?.display && (
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
            <Link to="/nft">
              {config.layoutType !== "dark-header" ? (
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/img/logos/default-dark.svg")}
                  className="h-20px h-lg-30px app-sidebar-logo-default"
                />
              ) : (
                <>
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/img/logos/default.svg")}
                    className="h-20px h-lg-30px app-sidebar-logo-default theme-light-show"
                  />
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/img/logos/default-dark.svg")}
                    className="h-20px h-lg-30px app-sidebar-logo-default theme-dark-show"
                  />
                </>
              )}
            </Link>
          </div>
        )}

        <div
          id="kt_app_header_wrapper"
          className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
          style={{
            flexDirection: "row-reverse",
          }}
        >

          <Navbar />
        </div>
      </div>
    </div>
  );
}
