import clsx from "clsx";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaIcons } from "../../helpers/FaIconHelper";
import { TabItem, Table } from "../../components";
import { useSelector } from "react-redux";
import { getChainsSelector } from "../../store/chain/selectors";
import { Chains, IChain } from "../../types/Chain";
import NFTDappService from "../../services/NFTDappService";
import { IDenomination, IDenominations, INFT } from "../../types/MultiTransfer";
import { formatCurrency, getExplorerLink } from "../../helpers";
import { CurrencySymbols } from "../../types/CurrencyBalance";
import { TListModel } from "../../types/Api";
import { Address } from "../../components/token/Address";
import Pagination from '../../components/common/pagination/Pagination';
const OverviewOwnedNftsModal = ({ show, onHide }: { show: boolean, onHide: any }) => {
  const chains = useSelector(getChainsSelector);
  const [filter, setFilter] = useState<any>({
    chainCode: Chains.POLYGON,
    page: 1,
    size: 6

  });
  const handleChange = (params: any) => {
    setFilter((prev: any) => ({ ...prev, ...params }));
  };
  return (
    <Modal
      className={clsx(
        "modal-sticky modal-sticky-lg modal-sticky-bottom-right",
      )}
      id="kt_mega_menu_modal"
      aria-hidden="true"
      tabIndex="-1"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName="modal-dialog mw-800px"
      onHide={onHide}
    >
      <div className="row">
        <div className="col-6">
          <div className="modal-header border-0 ">
            <h2>Tổng quan kho NFT</h2>
          </div>
          <div className="modal-body py-0">
            <SummaryNfts handleChange={handleChange} filter={filter} chains={chains} />
          </div>
          <div className="modal-footer border-0 pt-0">
          </div>
        </div>
        <div className="col-6">
          <div className="modal-header border-0 ">
            <h2>Danh sách chi tiết</h2>
            <button onClick={onHide} className="btn btn-sm btn-icon btn-active-color-primary">
              <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
            </button>
          </div>
          <div className="modal-body py-0">

            <NftList filter={filter} chains={chains} handleChange={handleChange} />
          </div>
          <div className="modal-footer border-0 pt-0">
          </div>
        </div>
      </div>
    </Modal >
  )
}

export default OverviewOwnedNftsModal;

const SummaryNfts = ({ handleChange, filter, chains }: { handleChange: any, filter: any, chains: IChain[] }) => {


  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<IDenominations>();
  useEffect(() => {
    loadSummary()
  }, [filter])

  const loadSummary = async () => {
    setLoading(true);

    const data = await NFTDappService.summary(filter);
    setSummary(data);
    setLoading(false);
  }

  const totalNFTs = summary?.denominations?.reduce((total: number, nft) => {
    return total + nft?.quantity
  }, 0)

  const columns = [
    {
      title: "Đơn giá",
      dataIndex: "denomination",
      key: "denomination",
      className: "text-end",
      render: (data: IDenomination) => <div className="text-end">{formatCurrency(data.value, CurrencySymbols.VIC)}</div>,
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
      className: "text-center",
    },
    {
      title: "Giá trị",
      dataIndex: "value",
      key: "value",
      className: "text-end",
      render: (number: number) => <div className="text-end">{formatCurrency(number, CurrencySymbols.VIC)}</div>,
    },
  ];

  return (<>
    <div>
      <div className="d-flex  gap-1 bg-white rounded p-1 text-center">
        {chains.map((item: any) => {
          return <>
            {item.name !== "TRON" && (<TabItem
              key={item}
              label={item.name}

              onClick={() => {
                handleChange({ chainCode: item.name, page: 1, size: 6 })

              }}
              active={item.name === filter.chainCode}
              activeClass="bg-primary text-white"
            />)}

          </>
        })}
      </div>
      <Table loading={loading} columns={columns} dataSource={summary?.denominations || []} />
      <hr />
      <div className="row  fw-bold">
        <div className="col text-center">TỔNG CỘNG</div>
        <div className="col text-center ">{totalNFTs}</div>
        <div className="col text-end ">{
          formatCurrency(summary?.totalValue || 0, CurrencySymbols.VIC)}</div>
      </div>
    </div></>)
}

const NftList = ({ filter, chains, handleChange }: { filter: any, chains: IChain[], handleChange: any }) => {

  const chain = chains.find(chain => chain.name === Chains.POLYGON);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TListModel<INFT>>();
  const [chainActive, setChainActive] = useState<any>()
  const [page, setPage] = useState(1);
  const [subfilter, setFilter] = useState<any>({});
  useEffect(() => {

    loadData();

  }, [page, filter])
  // console.log("_chain", filter)
  const loadData = async () => {
    setLoading(true);

    const _chain = chains.find(chain => chain.name === filter["chainCode"]);
    setChainActive(_chain)
    // const chainCode = filter["chainCode"] ?? _chain?.name

    const data = await NFTDappService.getMyNFTs(
      filter
    );

    setData(data);
    setLoading(false);
  }


  const columns = [
    {
      title: "Mã NFT",
      dataIndex: "tokenId",
      key: "tokenId",
      render: (tokenId: number) => <div className="">
        {chain?.contracts.VNFT.address && <a href={getExplorerLink(chain?.id, chain?.contracts.VNFT.address + `?a=${tokenId}`, 'token')} target="_blank" rel="noreferrer">#{tokenId}</a>}

      </div>
    },
    {
      title: "Giá trị",
      dataIndex: "attributes",
      key: "attributes",
      className: "text-end",
      render: (attributes: any) => <div className="text-end">{formatCurrency(attributes.value, CurrencySymbols.VIC)}</div>,
    },
  ];

  return <><div className="d-flex">Địa chỉ hợp đồng:
    {chainActive !== undefined ? <>
      {
        chainActive?.contracts.VNFT.address && <div className="d-flex align-content-center justify-content-center px-4"><Address
          linkClassName="text-primary "
          address={chainActive?.contracts.VNFT.address}
          link={getExplorerLink(chainActive?.id, chainActive?.contracts.VNFT.address, 'token')}
        /></div>
      }
    </> : <>
      {chain?.contracts.VNFT.address && <div className="d-flex align-content-center justify-content-center px-4"><Address
        linkClassName="text-primary "
        address={chain?.contracts.VNFT.address}
        link={getExplorerLink(chain?.id, chain?.contracts.VNFT.address, 'token')}
      /></div>}
    </>}


  </div>
    <div className="h-100 w-100 pt-4 ">
      {data && <Table className="w-100 h-100 " loading={loading} columns={columns} dataSource={data.items} />}
      {data && data?.totalPages > 1 && (<> <Pagination
        onPageChange={(page: number) => handleChange({ page })}
        page={data?.page || 0}
        limit={data?.perPage || 0}
        total={data?.totalItems || 0}
      /></>)}

    </div>
  </>

}