import clsx from 'clsx';
import React from 'react';
import { showDateTime, showTime } from '../../../helpers';
import { FontWeight, TextColor } from '../Label';

type Align = 'center' | 'start' | 'end';

type FontStyle = {
  textColor?: TextColor;
  fontWeight?: FontWeight;
  fontSize?: number;
};

type Props = {
  datetime?: FontStyle;
  time?: FontStyle;
  value: string;
  isInline?: boolean;
  isShowTime?: boolean;
  align?: Align;
};

const DateTime: React.FC<Props> = ({ value, datetime, time, isShowTime, isInline, align }) => {
  return (
    <>
      {value && (
        <div className={clsx(`d-flex flex-wrap`, align && `justify-content-${align}`)}>
          <div
            className={clsx('d-flex flex-wrap align-items-baseline', Object.values({ ...datetime }), {
              'flex-column': !isInline,
            })}
          >
            <span className="fw-bold">{showDateTime(value, 'Date')}</span>
            <span style={{ fontSize: 12 }} className={clsx('text-gray-600', { 'ms-1': isInline })}>
              {showDateTime(value, 'Time')}
            </span>
          </div>
          {isShowTime && <span className={clsx(Object.values({ ...time }))}>{showTime(value)}</span>}
        </div>
      )}
    </>
  );
};

export default DateTime;
